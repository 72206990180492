import axios from 'axios';
import React, { Component, Fragment } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import AppURL from '../../api/AppURL';


export class SuggestedProduct extends Component {

    constructor(){
        super();

        this.state = {
            ProductData: []
        }
    }

    componentDidMount (){
        let sub_category = this.props.subcategory;
        console.log(sub_category);
        axios.get(AppURL.SimilarProduct(sub_category)).then(response => {

                if (response.status == 200 ){
                    this.setState({
                        ProductData: response.data
                    });
                }

        }).catch(error => {
                
        });
    }

  render() {

           const  MyList = this.state.ProductData;
            if (MyList.length > 0 ){
                const MyView = MyList.map((mylist, i) => {

                    if (mylist.special_price == 0 ){

                    return          <Col className='p-1' key={i.toString()} xl={2} lg={2} md={2} sm={4} xs={6}> 
                            <Link className='text-link' to={'productDetails/'+ mylist.id} >

                            <Card className='image-box card'>
                                <img className='center' src={mylist.product_image} width="20" height="20"/>
                    
                            <Card.Body>
                                <p className='product-name-on-card'> {mylist.title} </p>
                                     <p className='product-price-on-card'> price : ${mylist.price} </p>
                               
                            </Card.Body>
                            </Card>
                            </Link>
                            </Col>
                            
        }else {
            return          <Col className='p-1' key={i.toString()} xl={2} lg={2} md={2} sm={4} xs={6}> 
                             <Link className='text-link' to={'productDetails/'+ mylist.id} >

                            <Card className='image-box card'>
                                <img className='center' src={mylist.product_image} width="20" height="20"/>
                    
                            <Card.Body>
                                <p className='product-name-on-card'> {mylist.title} </p>
                                     <p className='product-price-on-card'> price : <strike className='text-secondary'> ${mylist.price}</strike> 
                                             &nbsp; ${mylist.special_price}
                                      </p>
                               
                            </Card.Body>
                            </Card>
                            </Link>
                            </Col>
        }

                });

                return ( 
                    <Fragment>
                    <Container className='text-center' fluid={true}>
                        <div className='section-title text-center mb-55'> <h2> You May Also Like </h2>
                            <p> some of our exclusive collection, You may like </p>
                        </div>
                            <Row>
                            {MyView}
                            </Row>
                    </Container>
                    </Fragment>
                   )
            } else {
                return (
                    <Fragment>
                    <Container className='text-center' fluid={true}>
                        <div className='section-title text-center mb-55'> <h2> You May Also Like </h2>
                            <p> some of our exclusive collection, You may like </p>
                        </div>
                            <p> There are no similar products available. </p>
                    </Container>
             </Fragment>
                )
            }
           

    
  }
}

export default SuggestedProduct
