import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Fragment } from 'react'
import { Button, Card, Col, Container, ListGroup, ListGroupItem, Row } from 'react-bootstrap'
import { Link, Navigate, useLocation } from 'react-router-dom'
import AppURL from '../../api/AppURL';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function OrderProcess(props) {

    const {state} = useLocation();
    var [orders, setOrders] = useState([]);
    var [amounts, setAmount] = useState([]);
    var [currency, setCurrency] = useState([]);
    var total_count = 0;

    let name ;
    let email ;
    let type ;
    let userTypeFlage;

    if (props.user){
      name = props.user.name;
      email =props.user.email;
      type = props.user.user_type;

      if (type === 'Personal'){
        userTypeFlage = 'd-none';
      }else {
        userTypeFlage = '';
      }
      
    }

    useEffect(() => {
        axios.get(AppURL.getPersonOrders(state.invoice)).then(function(response){
            if (response.data.code === 1 ){
                setOrders(response.data.invoice);
               setAmount(response.data.total_amount);
                setCurrency(response.data.currency);
                
            } else {
                toast.error(response.data.message);
            }
            
        }).catch(function(error){

        });
    });

    const processAll = (e) => {

        axios.get(AppURL.processInvoice(state.invoice)).then(function(response){

            if (response.data.code === 1 ){
                toast.success(response.data.message);
            }else {
                toast.error(response.data.message);
            }

        }).catch(function(error){
            console.log(error);
        });
    }   

    const proceedOneItem = (e) => {

        axios.get(AppURL.processItem(e.target.getAttribute('data'))).then(function(response){

            if (response.data.code === 1 ){
                toast.success(response.data.message);
            }else {
                toast.error(response.data.message);
            }

        }).catch(function(error){
            console.log(error);
        });
    }

    const rejectOneItem = (e) => {

        axios.get(AppURL.rejectItem(e.target.getAttribute('data'))).then(function(response){

            if (response.data.code === 1 ){
                toast.success(response.data.message);
            }else {
                toast.error(response.data.message);
            }

        }).catch(function(error){
            console.log(error);
        });
    }

    

    const rejectAll = (e) => {
        
        axios.get(AppURL.rejectInvoice(state.invoice)).then(function(response){

            if (response.data.code === 1 ){
                toast.success(response.data.message);
            }else {
                toast.error(response.data.message);
            }

        }).catch(function(error){
            toast.error(error.data.message);
        });
    }

    if (!localStorage.getItem('token')){
      return <Navigate to='/login' />
    }

  return (
    <Fragment>


<div className='section-title text-center mb-55'> <h2> Process Order </h2>
                    
                    </div>
<Container >
  <Row>
    <Col lg={4} md={4} sm={12} xs={12} >

      <Card style={{maxWidth: '18rem', marginLeft: 0}}>
        <Card.Img className='userprofile' variant='top'/>
        <Card.Body>
          <Card.Title > {props.user.name} </Card.Title>

        </Card.Body>
          <ListGroup className='list-group-flush'>
            <ListGroupItem><Link className='text-link' to='/orderlist'> <p className='product-name-on-card'>  Order List</p> </Link></ListGroupItem>
            <ListGroupItem className={userTypeFlage}><Link className={'text-link '+ userTypeFlage} to='/delieveredorders'> <p className='product-name-on-card'>  Delievered Orders</p> </Link></ListGroupItem>
            <ListGroupItem className={userTypeFlage}><Link className={'text-link '+ userTypeFlage} to='/shippedorders'> <p className='product-name-on-card'>  Shipped Orders</p> </Link></ListGroupItem>
            <ListGroupItem className={userTypeFlage}><Link className={'text-link '+ userTypeFlage} to='/processedorders'> <p className='product-name-on-card'>  Processed Orders</p> </Link></ListGroupItem>
            <ListGroupItem className={userTypeFlage}><Link className={'text-link '+ userTypeFlage} to='/neworders'> <p className='product-name-on-card'>  New Orders</p> </Link></ListGroupItem>
            <ListGroupItem className={userTypeFlage}><Link className={'text-link '+ userTypeFlage} to='/addproduct'> <p className='product-name-on-card'>  Add Products</p> </Link></ListGroupItem>
            <ListGroupItem className={userTypeFlage}><Link className={'text-link '+ userTypeFlage} to='/products'> <p className='product-name-on-card'>  Products</p> </Link></ListGroupItem>
          
          </ListGroup>
         


      </Card>
      
    </Col>
    <Col lg={8} md={8} sm={12} xs={12} >

    
       
        {  orders.map((order, index) => {

            return (
                <Card className='image-box card w-100 mt-3' key={index}>
                                <Card.Header className='text-center bg-light'> <h5 className='text-primary'> <span className='text-dark'>Invoice No:</span>  { order.invoice_no} </h5></Card.Header>
                               

                                <Card.Body>
                                    <Card.Title className='text-center'><span className='text-info'>{order.product_name}</span></Card.Title>
                                    <p className='product-price-on-card'> {order.product_code} </p>
                                    <p className='product-name-on-card'> {order.product_size} </p>
                                    <p className='product-price-on-card'> {order.product_color} </p>

                                    <div>
                                        <h5 className='text-center'> Customer Info </h5>
                                        <p> {order.customer_name}</p>
                                        <p> {order.customer_contact_no}</p>
                                        <p> {order.delivery_address}</p>
                                        <p> {order.email}</p>
                                    </div>
                                
                                </Card.Body>

                                <Card.Footer className="text-muted text-center">
                                        <h6> <span className='text-danger'> Payment </span></h6>
                                        <p>Quantity: {order.quantity }</p>
                                        <p>Unit Price: {order.unit_price }</p>
                                        <p><span className='text-success'>Total Price: {order.total_price } </span></p>
                                        <p><span className='text-success'>Currency:  {order.currency }</span></p>
                                        <p>Payment Method: {order.payment_method }</p>

                                        <Button variant="primary me-2" data={order.id} onClick={proceedOneItem} >Proceed</Button>
                                        <Button variant="danger" data={order.id} onClick={rejectOneItem}>Reject</Button>
                                    </Card.Footer>
                            </Card>
            )
        })
        }
                            

            {orders.length === 0? 'No Record Exist': ''}            
                                     
    </Col> 
  </Row>

  <Row>
    <Col   lg={12} md={12} sm={12} xs={12}>

    <Card className="text-center">
      <Card.Header>Total Amounts to be Cashed from Customer</Card.Header>
      <Card.Body>
        <Card.Title><span className='text-success'> {amounts} : {currency} </span></Card.Title>
        <Card.Text>
          Support us by sharing your feedback with us, Thank you.
        </Card.Text>
        <Button variant="primary me-2" data={state.invoice} onClick={processAll}>Proceed</Button>
        <Button variant="danger" data={state.invoice} onClick={rejectAll}>Reject</Button>
      </Card.Body>
      <Card.Footer className="text-muted">2 days ago</Card.Footer>
    </Card>
    
    
    </Col>

  </Row>
</Container>
<ToastContainer theme="dark" />
        
      </Fragment>
  )
}

export default OrderProcess
