import React, { Component } from 'react'
import { Fragment } from 'react'

import HomePage from '../pages/HomePage';
import { BrowserRouter, Routes, Route, Switch } from 'react-router-dom';
import UserLoginPage from '../pages/UserLoginPage';
import ContactPage from '../pages/ContactPage';
import PurchasePage from '../pages/PurchasePage';
import PrivacyPage from '../pages/PrivacyPage';
import RefundPage from '../pages/RefundPage';
import ProductDetailsPage from '../pages/ProductDetailsPage';
import NotificationPage from '../pages/NotificationPage';
import FavouritePage from '../pages/FavouritePage';
import CartPage from '../pages/CartPage';
import AboutPage from '../pages/AboutPage';
import ProductCategoryPage from '../pages/ProductCategoryPage';
import ProductSubCategoryPage from '../pages/ProductSubCategoryPage';
import SearchPage from '../pages/SearchPage';
import RegisterPage from '../pages/RegisterPage';
import ForgetPasswordPage from '../pages/ForgetPasswordPage';
import ResetPasswordPage from '../pages/ResetPasswordPage';
import ProfilePage from '../pages/ProfilePage';
import AppURL from '../api/AppURL';
import axios from 'axios';
import NavMenuDesktop from '../components/common/NavMenuDesktop';
import OrderListPage from '../pages/OrderListPage';
import OrderProcessedPage from '../pages/OrderProcessedPage';
import AddProductPage from '../pages/AddProductPage';
import ShippedOrdersPage from '../pages/ShippedOrdersPage';
import DeliveredOrdersPage from '../pages/DeliveredOrdersPage';
import NewOrdersPage from '../pages/NewOrdersPage';
import ProductListPage from '../pages/ProductListPage';
import ProductEditPage from '../pages/ProductEditPage';
import OrderProcessPage from '../pages/OrderProcessPage';



export class AppRoute extends Component {

  constructor(){
    super()
    this.state={
      user: {},
    }
  
  }


  componentDidMount (){
    axios.post(AppURL.UserData).then(response => {
            this.setUser(response.data);
    }).catch(error => {
            
    });
}

  setUser =(user) => {

    this.setState({user: user});
  }
  render() {
    return (
      <Fragment>
        
        <NavMenuDesktop user={this.state.user} setUser={this.setUser} />
                <Routes>
                    <Route exact path="/" element={<HomePage user={this.state.user} />} />  
                    {/* <Route exact path="/" render={(props) => {<HomePage {...props} key={Date.now()} />}} /> 
                    <Route exact path="/login" render={(props) => {<UserLoginPage {...props} key={Date.now()} />}} /> 
                    <Route exact path="/contact" render={(props) => {<ContactPage {...props} key={Date.now()} />}} /> 
                    <Route exact path="/purchase" render={(props) => {<PurchasePage {...props} key={Date.now()} />}} /> 
                    <Route exact path="/privacy" render={(props) => {<PrivacyPage {...props} key={Date.now()} />}} /> 
                    <Route exact path="/refund" render={(props) => {<RefundPage {...props} key={Date.now()} />}} /> 
                    <Route exact path="/about" render={(props) => {<AboutPage {...props} key={Date.now()} />}} /> 

                    <Route exact path="//productdetails/:code" render={(props) => {<ProductDetailsPage {...props} key={Date.now()} />}} /> 
                    <Route exact path="/notification" render={(props) => {<NotificationPage {...props} key={Date.now()} />}} /> 
                    <Route exact path="/favourite" render={(props) => {<FavouritePage {...props} key={Date.now()} />}} /> 
                    <Route exact path="/cart" render={(props) => {<CartPage {...props} key={Date.now()} />}} /> 
                    <Route exact path="/productcategory/:category" render={(props) => {<ProductCategoryPage {...props} key={Date.now()} />}} /> 
                    <Route exact path="/productsubcategory/:category/:subcategory" render={(props) => {<ProductSubCategoryPage {...props} key={Date.now()} />}} />  */}


                    <Route exact path="/login" element={<UserLoginPage user={this.state.user} setUser={this.setUser} />} />  
                    <Route exact path="/contact" element={<ContactPage />} /> 
                    <Route exact path="/purchase" element={<PurchasePage />} /> 
                    <Route exact path="/privacy" element={<PrivacyPage />} />
                    <Route exact path="/refund" element={<RefundPage />} />
                    <Route exact path="/productdetails/:code" element={<ProductDetailsPage user={this.state.user} />} />  
                    <Route exact path="/notification" element={<NotificationPage />} />  
                    <Route exact path="/favourite" element={<FavouritePage user={this.state.user} />} /> 
                    <Route exact path="/cart" element={<CartPage user={this.state.user}/>} /> 
                    <Route exact path="/about" element={<AboutPage />} /> 
                    <Route exact path="/productcategory/:category"  element={<ProductCategoryPage />} /> 
                    <Route exact path="/productsubcategory/:category/:subcategory"  element={<ProductSubCategoryPage />} /> 
                    <Route exact path="/productlistsearch/:searchKey"  element={<SearchPage />} /> 
                    <Route exact path="/register"  element={<RegisterPage user={this.state.user} setUser={this.setUser}  />} />
                    <Route exact path="/forget"  element={<ForgetPasswordPage />} />
                    <Route exact path="/reset/:id"  element={<ResetPasswordPage />} />
                    <Route exact path="/profile"  element={<ProfilePage user={this.state.user} setUser={this.setUser} key={Date.now()} />} />
                    <Route exact path="/orderlist"  element={<OrderListPage user={this.state.user} key={Date.now()} />} />
                    <Route exact path="/processedorders"  element={<OrderProcessedPage user={this.state.user} key={Date.now()} />} />
                    <Route exact path="/addproduct"  element={<AddProductPage user={this.state.user} key={Date.now()} />} />
                    <Route exact path="/neworders"  element={<NewOrdersPage user={this.state.user} key={Date.now()} />} />
                    <Route exact path="/shippedorders"  element={<ShippedOrdersPage user={this.state.user} key={Date.now()} />} />
                    <Route exact path="/delieveredorders"  element={<DeliveredOrdersPage user={this.state.user} key={Date.now()} />} />
                    <Route exact path="/products"  element={<ProductListPage user={this.state.user} key={Date.now()} />} />
                    <Route exact path="/products/edit"  element={<ProductEditPage user={this.state.user} key={Date.now()} />} />
                    <Route exact path="/products/process"  element={<OrderProcessPage user={this.state.user} key={Date.now()} />} />
                </Routes>
        
      </Fragment>
    )
  }
}

export default AppRoute
