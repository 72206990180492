import React, { Component, Fragment } from 'react'
import NavMenuDesktop from '../components/common/NavMenuDesktop'
import NavMenuMobile from '../components/common/NavMenuMobile'
import FooterDesktop from '../components/common/FooterDesktop';
import FooterMobile from '../components/common/FooterMobile';
import Category from '../components/ProductDetails/Category';
import axios from 'axios';
import AppURL from '../api/AppURL';
import {  useParams } from 'react-router';
import FCategory from '../components/ProductDetails/FCategory';
import { useState } from 'react';
import { useEffect } from 'react';

 export function ProductCategoryPage (props) {
    // constructor(props){
    //     super();
       
    //     this.state = {
    //         Category: props.FCategory,
    //         ProductData: []
    //     }
    // }
    const params = useParams();
    var [category, setCategory] = useState();
    var [productdata, setProductData] = useState([]);

    useEffect(() => {
         setCategory(params.category);
        //console.log(props);
        window.scroll(0,0);
        //alert(this.state.category);

        axios.get(AppURL.productlistbycategory(category)).then(
              response => {
                  // this.setState({ProductData: response.data});
                  setProductData(response.data);
              }
        ).catch(
            error => {

            }
        );
    });


    return (
      <>
        <Fragment>
        <div className='Desktop'>
          <NavMenuDesktop />
        </div>
        <div className='Mobile'>
        <NavMenuMobile />
        </div>
      
        <Category Category={category} ProductData={productdata} />
        
        <div className='Desktop'>
        <FooterDesktop />
        </div>

        <div className='Mobile'>
          <FooterMobile />
        </div>
      </Fragment>
      </>
    )
  
}

export default ProductCategoryPage

