import axios from 'axios';
import React, { Component, Fragment } from 'react'
import { Breadcrumb, Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AppURL from '../../api/AppURL';
import CategoryLoading from '../PlaceHolder/CategoryLoading';

export class SearchList extends Component {
    constructor(){
        super();
    
        this.state = {
            MenuData: [],
            isLoading: '',
            mainDiv: 'd-none'
        }
      }

      componentDidMount (){
        axios.get(AppURL.AllCategoryDetails).then(response => {
                if (response.status == 200 ){
                    this.setState({
                        MenuData: response.data,
                        isLoading: 'd-none',
                        mainDiv: ''
                    });
                }
        }).catch(error => {
                
        });
    }

  render() {

    const MyList  = this.props.ProductData ;
    const SearchKey  = this.props.search_key ;

    console.log(this.props)

    const MyView = MyList.map((ProductList, i ) => {

        if (ProductList.special_price == 0 ){
            
        return           <Col className='p-0' xl={3} lg={3} md={3} sm={6} xs={6} >
                             <Link className='text-link' to={'productDetails/'+ ProductList.id} >
                            <Card className='image-box card w-100'>
                                <img className='center w-75' src={ProductList.product_image} width="20" height="20"/>

                                <Card.Body>
                                    <p className='product-name-on-card'> {ProductList.title} </p>
                                    <p className='product-price-on-card'> price : ${ProductList.price} </p>
                                
                                </Card.Body>
                            </Card>
                            </Link>
                        </Col>
                            
        }else {
            return           <Col className='p-0' xl={3} lg={3} md={3} sm={6} xs={6} >
                             <Link className='text-link' to={'productDetails/'+ ProductList.id} >
                            <Card className='image-box card w-100'>
                                <img className='center w-75' src={ProductList.product_image} width="20" height="20"/>

                                <Card.Body>
                                    <p className='product-name-on-card'> {ProductList.title} </p>
                                    <p className='product-price-on-card'> price : $ <strike>{ProductList.price} </strike>
                                        &nbsp; {ProductList.special_price}
                                     </p>
                                
                                </Card.Body>
                            </Card>
                            </Link>
                        </Col>
        }
    });

    return (
      <Fragment>

        <CategoryLoading isLoading={this.state.isLoading} />

        <div className={this.state.mainDiv}>
      <Container className='text-center' fluid={true}>

      <div className='breadbody'>
        <Breadcrumb>
      <Breadcrumb.Item > <Link to="/">Home</Link> </Breadcrumb.Item>
      <Breadcrumb.Item > <Link to="/">{SearchKey}</Link> </Breadcrumb.Item>
      <Breadcrumb.Item ><Link to={"/productlistsearch/" +SearchKey} >Search For:  {SearchKey}</Link></Breadcrumb.Item>
    </Breadcrumb>
    </div>

      <div className='section-title text-center mb-40 mt-2'> <h2> {SearchKey}</h2>
                  <p> some of our exclusive collection, You may like </p>
              </div>
              <Row>   
                  {MyView}

              </Row>
          </Container>
          </div>
    </Fragment>
    )
  }
}

export default SearchList
