import React, { Component, Fragment, useEffect, useState } from 'react'
import NavMenuDesktop from '../components/common/NavMenuDesktop'
import NavMenuMobile from '../components/common/NavMenuMobile'
import FooterDesktop from '../components/common/FooterDesktop'
import FooterMobile from '../components/common/FooterMobile'
import SubCategory from '../components/ProductDetails/SubCategory'
import axios from 'axios'
import AppURL from '../api/AppURL'
import Category from '../components/ProductDetails/Category'
import { useParams } from 'react-router'
export function ProductSubCategoryPage () {

    
      const params = useParams();
      var [category, setCategory] = useState();
      var [productdata, setProductData] = useState([]);
      var [subcategory, setSubCategory] = useState();
  
      useEffect(() => {
           setCategory(params.category);
           setSubCategory(params.subcategory);
          
          window.scroll(0,0);
          
  
          axios.get(AppURL.productlistbysubcategory(category, subcategory)).then(
                response => {
                    
                    setProductData(response.data);
                }
          ).catch(
              error => {
  
              }
          );
      });



    return (
        <Fragment>
        <div className='Desktop'>
          <NavMenuDesktop />
        </div>
        <div className='Mobile'>
        <NavMenuMobile />
        </div>

        <SubCategory Category={category} SubCategory={subcategory} ProductData={productdata} />
      
       
        
        <div className='Desktop'>
        <FooterDesktop />
        </div>

        <div className='Mobile'>
          <FooterMobile />
        </div>
      </Fragment>
    )

}

export default ProductSubCategoryPage
