import React, { Component, Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faFacebook, faFacebookF, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Apple from '../../assets/images/apple.png'
import Google from '../../assets/images/google.png'


export class footerMobile extends Component {
  render() {
    return (
      <Fragment >

      <div className='footerback m-0 mt-5 pt-3 shadow-sm'>
        <Container className='text-center'>
            <Row className='px-0 my-5' lg={3} md={3} sm={6} xs={12}>
                <Col className='p-2' lg={3} md={3} sm={6} xs={12}>
                      <h5 className='footer-menu-title'> Office Address </h5>
                      <p> Street Address <br></br>
                      Email :  email@email.com
                      </p>
                      <h5 className='footer-menu-title'> Social Link </h5>
                      <a href='#'><FontAwesomeIcon className='m1 h4' icon={faFacebook} /> </a>
                      <a href='#'><FontAwesomeIcon className='m1 h4' icon={faInstagram} /> </a>
                      <a href='#'><FontAwesomeIcon className='m1 h4' icon={faTwitter} /> </a>
                      <a href='#'><FontAwesomeIcon className='m1 h4' icon={faLinkedin} /> </a>
                </Col>
                

                <Col className='p-2' lg={3} md={3} sm={6} xs={12}>
                <h5 className='footer-menu-title'> Download App</h5>
                <a><img src={Apple} /></a><br></br>
                <a><img src={Google} className='mt-2' /></a><br></br>
                </Col>
            </Row>
        </Container>

          <Container fluid={true} className='text-center m-0 pt-3 pb-1 bg-dark'>
              <Container>
                  <Row>
                    <h6 className='text-white'>Copyright all rights reserved.</h6>
                  </Row>
              </Container>

          </Container>
        </div>
    </Fragment>
    )
  }
}

export default footerMobile
