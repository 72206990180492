import React, { Component } from 'react'
import menuicon from '../../assets/images/picture.png';
import axios from 'axios';
import AppURL from '../../api/AppURL';
import { Link } from 'react-router-dom';


export class MegaMenuAll extends Component {

    constructor(){
        super();
        this.state = {
            MenuData: []
        }
    }
    componentDidMount(){
        axios.get(AppURL.AllCategoryDetails) . then (response => {
            
            this.setState({
                MenuData : response.data
            });
          }) . catch(error => {
      
          });
    }

    MenuItemClick = (event) => {
        event.target.classList.toggle("active");

        var panel = event.target.nextElementSibling;
                    if (panel.style.maxHeight){
                        panel.style.maxHeight = null;
                    }else {
                        panel.style.maxHeight = panel.scrollHeight + "px";
                       
                    }
    }

  
  render() {

    
    const catList  = this.state.MenuData ;
    const myView = catList.map((catList, i) => {

        return <div key={i.toString()}>

                        <button key={catList.category_id} className='accordionAll' onClick={this.MenuItemClick}><img className='accordionMenuIconAll' src={ catList.category_image_path} /> {catList.category_name}</button>
                        <div className='panelAll'>
                                <ul>
                                    {
                                        (catList.subcategories).map((subList, i) => {
                                                return <li> <Link to={"/productsubcategory/" + catList.category_name + "/" + subList.subcategory_name  } key={subList.id} className='accordionItem'> {subList.subcategory_name} </Link></li>

                                        })
                                    }
                                </ul>
                        </div>

        </div>

    });

    return (
        <div className='accordionMenuDivAll'>
        <div className='accordionMenuDivInsideAll'>
           
            {myView}
        </div>

        

      </div>
    )
  }
}

export default MegaMenuAll
