import React, { Component } from 'react'
import { Fragment } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import AppURL from '../../api/AppURL';
import NewArrivalLoading from '../PlaceHolder/NewArrivalLoading';
import { Link } from 'react-router-dom';


export class NewArrival extends Component {

    constructor(props){
            super(props);
            this.state = {
              ProductData: [],
              isLoading: '',
              mainDiv: 'd-none'
          }
            this.next = this.next.bind(this);
            this.previous = this.previous.bind(this);
    }
    next(){
        this.Slider.slickNext();
    }
    previous(){
        this.Slider.slickPrev();
    }

    componentDidMount (){
      axios.get(AppURL.productlistbyremark('New')).then(response => {

              if (response.status == 200 ){

                  this.setState({
                      ProductData: (response.data),
                      isLoading: 'd-none',
                      mainDiv: ''
                  
                  });
              }

      }).catch(error => {
              
      });
  }


  render() {


    const NewList  = this.state.ProductData ;

    const MyView = NewList.map((NewList, i ) => {

        if (NewList.special_price == 0.00 ){
            
        return            <div key={i.toString}>
                        <Link className='text-link' to={'productDetails/'+ NewList.id} >
                          <Card className='image-box card'>
                                <img className='center' src={NewList.product_image} width="20" height="20"/>
                      
                            <Card.Body>
                                <p className='product-name-on-card'> {NewList.title} </p>
                                <p className='product-price-on-card'> price : ${NewList.price} </p>
                            
                            </Card.Body>
                            </Card>
                            </Link>
                          </div>
                            
        }else {
            return            <div key={i.toString}>
              <Link className='text-link' to={'productDetails/'+ NewList.id} >
            <Card className='image-box card'>
                  <img className='center' src={NewList.product_image} width="20" height="20"/>
        
              <Card.Body>
                  <p className='product-name-on-card'> {NewList.title} </p>
                  <p className='product-price-on-card'> price : $ <strike className="text-secondary"> {NewList.price}</strike>
                  &nbsp; {NewList.special_price} </p>
              
              </Card.Body>
              </Card>
              </Link>
            </div>
        }
    });






    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
    return (
      <Fragment>
          <NewArrivalLoading isLoading={this.state.isLoading}/>
            <div className={this.state.mainDiv}>
            <Container className='text-center'>
            <div className='section-title text-center mb-55'> <h2> New Arrival &nbsp;
            <a className='btn btn-sm ml-2 site-btn' onClick={this.previous}> <FontAwesomeIcon icon={faAngleLeft}/> </a>
                        <a className='btn btn-sm ml-2 site-btn' onClick={this.next} > <FontAwesomeIcon icon={faAngleRight}/></a>

            </h2>
                        
                    <p> some of our exclusive collection, You may like </p>
                </div>
                <Row>
                <Slider ref={c=>(this.Slider=c)} {...settings}>

                  {MyView}
       
                </Slider>
                      
                </Row>
            </Container>
            </div>
      </Fragment>
    )
  }
}

export default NewArrival
