import React, { Component, Fragment } from 'react'
import { Breadcrumb, Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export class SubCategory extends Component {
  render() {

    const MyList  = this.props.ProductData ;
    const Category  = this.props.Category ;
    const SubCategory  = this.props.SubCategory ;

    const MyView = MyList.map((ProductList, i ) => {

        if (ProductList.special_price == 0 ){
            
        return           <Col className='p-0' xl={3} lg={3} md={3} sm={6} xs={6} >
                            <Link className='text-link' to={'/productDetails/'+ ProductList.id} >
                            <Card className='image-box card w-100'>
                                <img className='center w-75' src={ProductList.product_image} width="20" height="20"/>

                                <Card.Body>
                                    <p className='product-name-on-card'> {ProductList.title} </p>
                                    <p className='product-price-on-card'> price : ${ProductList.price} </p>
                                
                                </Card.Body>
                            </Card>
                            </Link>
                        </Col>
                            
        }else {
            return           <Col className='p-0' xl={3} lg={3} md={3} sm={6} xs={6} >
                             <Link className='text-link' to={'/productDetails/'+ ProductList.id} >
                            <Card className='image-box card w-100'>
                                <img className='center w-75' src={ProductList.product_image} width="20" height="20"/>

                                <Card.Body>
                                    <p className='product-name-on-card'> {ProductList.title} </p>
                                    <p className='product-price-on-card'> price : $ <strike>{ProductList.price} </strike>
                                        &nbsp; {ProductList.special_price}
                                     </p>
                                
                                </Card.Body>
                            </Card>
                            </Link>
                        </Col>
        }
    });

    return (
        <Fragment>
        <Container className='text-center' fluid={true}>

        <div className='breadbody'>
        <Breadcrumb>
      <Breadcrumb.Item > <Link to="/">Home</Link> </Breadcrumb.Item>
      <Breadcrumb.Item ><Link to={"/productcategory/" +Category} >{Category}</Link></Breadcrumb.Item>
      <Breadcrumb.Item ><Link to={"/productlistbysubcategory/" +Category + "/" + SubCategory} >{SubCategory}</Link></Breadcrumb.Item>
    </Breadcrumb>
    </div>
  
        <div className='section-title text-center mb-40 mt-2'> <h2> {Category} / { SubCategory}</h2>
                    <p> some of our exclusive collection, You may like </p>
                </div>
                <Row>   
                    {MyView}
  
                </Row>
            </Container>
      </Fragment>
    )
  }
}

export default SubCategory
