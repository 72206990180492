import React, { Component, Fragment } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import validation from '../../Validation/validation';
import axios from 'axios'
import AppURL from '../../api/AppURL';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class Contact extends Component {

    constructor(){
        super();
        this.state = {
            name : "",
            email : "",
            message : ""
        }
    }

    nameOnChange = (event) => {
        let name = event.target.value;
        this.setState({name: name});
    }
    emailOnChange = (event) => {
        let email = event.target.value;
        this.setState({email: email});
    }
    messageOnChange = (event) => {
        let message = event.target.value;
        this.setState({message: message});
    }

    onFormSubmit = (event) => {
        event.preventDefault();
            let name = this.state.name;
            let email = this.state.email;
            let message = this.state.message;
            let sendbtn = document.getElementById('sendBtn');

            if (message == 0 ){
                toast.error('Please write your message ');
            } else if (name.length == 0 || name.length >= 191){
                toast.error('write down your name ');
            }else if (email.length == 0 || email.length >= 191 ){
                toast.error('write down your email please Or check your email address length');
            }else if (!(validation.NameRegex).test(name)){
                toast.error('correct the name ')
            } else {
                sendbtn.innerHTML = 'Sending...';
                let MyFormData = new FormData();
                MyFormData.append("name", name);
                MyFormData.append("email", email);
                MyFormData.append("message", message);

                axios.post(AppURL.PostContact, MyFormData)
                .then(function(response){
                    if (response.status == 200 && response.data.code == 1){
                        toast.success(  response.data.message);
                            sendbtn.innerHTML = "Send";
                            document.getElementById('contact_form').reset();
                    }
                })
                .catch(function(error){
                    toast.error(error);
                        sendbtn.innerHTML = "Send";
                });
            }
    }
  render() {
    return (
        <Fragment>
        <Container>
            <Row className='p-2'>
                <Col className='shadown-sm bg-white mt-2' lg={12} md={12} sm={12} xs={12}>

                    <Row className='text-center'>
                        <Col className='d-flux justify-content-center'  lg={6} md={6} sm={12} xs={12}>
                        
                            <Form id='contact_form' onSubmit={this.onFormSubmit} className='onboardForm'>
                                <h4 className='section-title-login'>  Contact US</h4>
                                <h6 className='section-sub-title'></h6>
                                <h6 > Please Contact With Us </h6>
                                <input onChange={this.nameOnChange} className='form-control m-2' type='text' placeholder='Enter Your Name' />

                                <input onChange={this.emailOnChange} className='form-control m-2' type='email' placeholder='Enter Email' />
                                <Form.Control onChange={this.messageOnChange} className='form-control m-2' placeholder='Enter your message' as="textarea" rows={3}/>
                               
                                <Button id='sendBtn' type="submit" className='btn btn-blok m-2 site-btn-login'>Send </Button>
                            </Form>
                        </Col>
                        
                        <Col className='p-0 Desktop m-0' lg={6} md={6} sm={6} xs={6}>
                            <br></br><br></br>
                        <p className='section-title-contact'>
                        Street Address
                        Email : email@email.com
                        </p>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13795.813803483006!2d66.95320107936868!3d30.18132365824979!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ed2e0e6228e5cf5%3A0x477b5c06e19744af!2sHazara%20Town%2C%20Quetta%2C%20Balochistan%2C%20Pakistan!5e0!3m2!1sen!2s!4v1667621874082!5m2!1sen!2s" width="600" height="450" styles="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </Col>
                        
                    </Row>
                
                </Col>
            </Row>
        </Container>
        <ToastContainer />
    </Fragment>
    )
  }
}

export default Contact
