import React, { Component } from 'react'
import { Fragment } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap';
import logo from '../../assets/images/app-icon.png';
import axios from 'axios';
import AppURL from '../../api/AppURL';
import { Link } from 'react-router-dom';
import CollectionLoading from '../PlaceHolder/CollectionLoading';


export class Collection extends Component {

    constructor(){
        super();
    
        this.state = {
            ProductData: [],
            isLoading: '',
            mainDiv: 'd-none'
        }
      }

      componentDidMount (){
        axios.get(AppURL.productlistbyremark('Collection')).then(response => {

                if (response.status == 200 ){

                    this.setState({
                        ProductData: (response.data),
                        isLoading: 'd-none',
                        mainDiv: ''
                    
                    });
                }

        }).catch(error => {
                
        });
    }


  render() {

    const CollectionList  = this.state.ProductData ;

    const MyView = CollectionList.map((CollectionList, i ) => {

        if (CollectionList.special_price == 0 ){
            
        return           <Col className='p-0' xl={3} lg={3} md={3} sm={6} xs={6} >
                            <Link className='text-link' to={'productDetails/'+ CollectionList.id} >
                            <Card className='image-box card w-100'>
                                <img className='center w-75' src={CollectionList.product_image} width="20" height="20"/>

                                <Card.Body>
                                    <p className='product-name-on-card'> {CollectionList.title} </p>
                                    <p className='product-price-on-card'> price : ${CollectionList.price} </p>
                                
                                </Card.Body>
                            </Card>
                            </Link>
                        </Col>
                            
        }else {
            return           <Col className='p-0' xl={3} lg={3} md={3} sm={6} xs={6} >
                            <Link className='text-link' to={'productDetails/'+ CollectionList.id} >
                            <Card className='image-box card w-100'>
                                <img className='center w-75' src={CollectionList.product_image} width="20" height="20"/>

                                <Card.Body>
                                    <p className='product-name-on-card'> {CollectionList.title} </p>
                                    <p className='product-price-on-card'> price : $ <strike>{CollectionList.price} </strike>
                                        &nbsp; {CollectionList.special_price}
                                     </p>
                                
                                </Card.Body>
                            </Card>
                            </Link>
                        </Col>
        }
    });

    return (
      <Fragment>
        <CollectionLoading isLoading={this.state.isLoading}/>
        <div className={this.state.mainDiv}>
        <Container className='text-center' fluid={true}>

        <div className='section-title text-center mb-55'> <h2> Product Collection</h2>
                    <p> some of our exclusive collection, You may like </p>
                </div>
                <Row>   
                    {MyView}

                </Row>
            </Container>
            </div>
      </Fragment>
    )
  }
}

export default Collection
