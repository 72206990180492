import axios from 'axios';
import React, { Component, Fragment } from 'react'
import { Card, Col, Container, Form, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import { Navigate } from 'react-router';
import { Link } from 'react-router-dom';
import AppURL from '../../api/AppURL';
import Pic1 from '../../assets/images/pic1.jpg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class Profile extends Component {

  constructor() {
    super();
    this.state = {picture: ''};
  }


  render() {

   const uploadPicture = (e) =>{


      let formData = new FormData();

      formData.append('image',e.target.files[0] );
      

      axios.post(AppURL.uploadProfilePicture, formData).then(function(response){

          if (response.data.code === 1 ){
            toast.success(response.data.message);
          }
        

      }).catch(function(error){

      }); 

        
    }

    let name ;
    let email ;
    let type ;
    let userTypeFlage;

    if (this.props.user){
      name = this.props.user.name;
      email = this.props.user.email;
      type = this.props.user.user_type;

      if (type === 'Personal'){
        userTypeFlage = 'd-none';
      }else {
        userTypeFlage = '';
      }
      
    }

    if (!localStorage.getItem('token')){
      return <Navigate to='/login' />
    }
    return (
      <Fragment>


<div className='section-title text-center mb-55'> <h2> Profile Page </h2>
                    
                    </div>
<Container >
  <Row>
    <Col lg={4} md={4} sm={12} xs={12} >

      <Card style={{maxWidth: '18rem', marginLeft: 0}}>
        <Card.Img className='userprofile' variant='top' src={this.props.user.profile_photo_path}/>
        <Card.Body>
          <Card.Title > Title </Card.Title>

        </Card.Body>
          <ListGroup className='list-group-flush'>
            <ListGroupItem><Link className='text-link' to='/orderlist'> <p className='product-name-on-card'>  Order List</p> </Link></ListGroupItem>
            <ListGroupItem className={userTypeFlage}><Link className={'text-link '+ userTypeFlage} to='/delieveredorders'> <p className='product-name-on-card'>  Delievered Orders</p> </Link></ListGroupItem>
            <ListGroupItem className={userTypeFlage}><Link className={'text-link '+ userTypeFlage} to='/shippedorders'> <p className='product-name-on-card'>  Shipped Orders</p> </Link></ListGroupItem>
            <ListGroupItem className={userTypeFlage}><Link className={'text-link '+ userTypeFlage} to='/processedorders'> <p className='product-name-on-card'>  Processed Orders</p> </Link></ListGroupItem>
            <ListGroupItem className={userTypeFlage}><Link className={'text-link '+ userTypeFlage} to='/neworders'> <p className='product-name-on-card'>  New Orders</p> </Link></ListGroupItem>
            <ListGroupItem className={userTypeFlage}><Link className={'text-link '+ userTypeFlage} to='/addproduct'> <p className='product-name-on-card'>  Add Products</p> </Link></ListGroupItem>
            <ListGroupItem className={userTypeFlage}><Link className={'text-link '+ userTypeFlage} to='/products'> <p className='product-name-on-card'>  Products</p> </Link></ListGroupItem>
          
          </ListGroup>
         
      </Card>
      
    </Col>
    <Col lg={8} md={8} sm={12} xs={12} >
    <ul className='list-group'>
            <li className='list-group-item'>
                Name : {name}  
            </li>
            <li className='list-group-item'>
                Email : {email}  
            </li>
            <li className='list-group-item'>
                Type : {type} 
            </li>

        </ul>

        <ul className='list-group'>
        <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>Upload your Picture</Form.Label>
        <Form.Control type="file" id='picture' onChange={uploadPicture} />
      </Form.Group>
        </ul>
    </Col> 
  </Row>
</Container>
<ToastContainer theme="dark" />
        
      </Fragment>
    )
  }
}

export default Profile
