import React, { Component } from 'react'

export class AppURL extends Component {
  
    static BaseURL = 'https://shopping.hazaraproperty.com/admin/public/api';
    static VisitorDetails = this.BaseURL + "/getvisitor";
    static PostContact = this.BaseURL + "/postcontact";
    static AllSiteInfo = this.BaseURL + "/siteinfo";
    static AllCategoryDetails = this.BaseURL + "/allcategory";

   static productlistbyremark(Remark) {
        return this.BaseURL + "/productlistbyremark/"+ Remark;
    }

    static  productlistbycategory(category) {
        return this.BaseURL + "/productlistbycategory/"+ category;
    }

    static productlistbysubcategory(category, subcategory) {
        return this.BaseURL + "/productlistbysubcategory/"+ category + "/" + subcategory;
    }
    static AllSlider = this.BaseURL + "/allslider";

    static ProductDetails(code) {
        return this.BaseURL + "/productdetails/"+ code;
    }
    static NotificationHistory = this.BaseURL + "/notification";

    static ProductSearch(search_key){
        return this.BaseURL + "/search/"+search_key;
    }

    static UserLogin = this.BaseURL + "/login";
    static UserData = this.BaseURL + "/user";
    static UserRegister = this.BaseURL + "/register";
    static ForgetPassword = this.BaseURL + "/forgetpassword";
    static ResetPassword = this.BaseURL + "/resetpassword";
    static SimilarProduct(code){
        return this.BaseURL + "/similar/"+code;
    }

    static ReviewList(product_code){
        return this.BaseURL + "/reviewlist/"+product_code;
    }
  
    static AddToCart = this.BaseURL + "/addtocart";
    
    static CartCount(product_code){
        return this.BaseURL + "/cartcount/"+product_code;
    }

    static AddFavourite(product_code, email) {
        return this.BaseURL + "/favourite/"+ product_code + "/" + email;
    }

    static FavouriteList(email) {
        return this.BaseURL + "/favouritelist/" + email;
    }

    static DeleteFavourite(product_code, email) {
        return this.BaseURL + "/favouriteremove/"+ product_code + "/" + email;
    }

    static CartList(email) {
        return this.BaseURL + "/cartlist/" + email;
    }

    static RemoveFromCart(pid) {
        return this.BaseURL + "/removefromcart/" + pid;
    }

    static CartItemPlus(pid, quantity, price) {
        return this.BaseURL + "/cartitemplus/" + pid + "/" + quantity + "/" + price;
    }

    static CartItemMinus(pid, quantity, price) {
        return this.BaseURL + "/cartitemminus/" + pid + "/" + quantity + "/" + price;
    }

    static CartOrder = this.BaseURL + "/cartorder";

    static OrderListByUser(email) {
        return this.BaseURL + "/orderlistbyuser/" + email;
    }

    static PostReview = this.BaseURL + "/postreview";
    static GenerateProductcode = this.BaseURL + "/generatecode";

    static findSubcategory(id){
        return this.BaseURL + "/findsubcategory/" + id;
    }

    static AddProduct = this.BaseURL + "/product/add";


    static AllProducts(email){
        return this.BaseURL + "/products/email/" + email ;
    }

    static deleteProduct(id){
        return this.BaseURL + '/delete/product/'+ id ;
    }

    static getProduct(id){
        return this.BaseURL + '/find/product/'+ id ;
    }
    
    static getOrders(email){
        return this.BaseURL + '/find/orders/'+ email ;
    }

    static getPersonOrders(invoice){
        return this.BaseURL + '/find/invoice/'+ invoice ;
    }

    static processInvoice(invoice){
        return this.BaseURL + '/process/invoice/' + invoice;
    }
    static rejectInvoice(invoice){
        return this.BaseURL + '/reject/invoice/' + invoice;
    }

    static rejectItem(id){
        return this.BaseURL + '/reject/item/' + id;
    }
    static processItem(id){
        return this.BaseURL + '/process/item/' + id;
    }

    static getInProcessOrders(){
        return this.BaseURL + '/inprocess/orders';
    }

    static sentForShipment(id){
        return this.BaseURL + '/deliver/item/' + id;
    }

    static getDeliveredItems(){
        return this.BaseURL + '/delivered/items';
    }

    static uploadProfilePicture = this.BaseURL + '/uploadProfileImage';

    static receivedItem(item){
        return this.BaseURL + '/received/item/'+ item;
    }
    
    

}

export default AppURL
