import {  } from '@fortawesome/free-regular-svg-icons'
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';
import React, { Component } from 'react'
import AppURL from '../../api/AppURL';

export class ReviewList extends Component {

    constructor(props){
        super(props);
        this.state = {
            ReviewData : [],
            
        }
    }

    componentDidMount (){
        let code = this.props.code;
       
        axios.get(AppURL.ReviewList(code)).then(response => {
                if (response.status == 200 ){
                    this.setState({
                        ReviewData: response.data,
                    });
                }
        }).catch(error => {
                
        });
        
    }

  render() {

            let reviews = this.state.ReviewData;
            if (reviews.length > 0 ){

           const MyView =  reviews.map((review, i ) => {
                

                        if (review.reviewer_rating == 1 ){

                            return ( <div>
                                <p className='p-0 m-0'> <span className='Review-Title'> {review.reviewer_name}  </span>
                            <span className='text-success'> <FontAwesomeIcon icon={faStar}/></span>
                            </p> <p> {review.reviewer_comments}</p>
                            </div>
                            )
                        }else if (review.reviewer_rating == 2 ){
                            return ( <div>
                                <p className='p-0 m-0'> <span className='Review-Title'> {review.reviewer_name}  </span>
                            <span className='text-success'> <FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/></span>
                            </p> <p> {review.reviewer_comments}</p>
                            </div>
                            )
                        }else if (review.reviewer_rating == 3 ){
                            return ( <div>
                                <p className='p-0 m-0'> <span className='Review-Title'> {review.reviewer_name}  </span>
                            <span className='text-success'> <FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/>
                            <FontAwesomeIcon icon={faStar}/></span>
                            </p> <p> {review.reviewer_comments}</p>
                            </div>
                            )
                        }else if (review.reviewer_rating == 4 ){
                            return ( <div>
                                <p className='p-0 m-0'> <span className='Review-Title'> {review.reviewer_name}  </span>
                            <span className='text-success'> <FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/>
                            <FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/></span>
                            </p> <p> {review.reviewer_comments}</p>
                            </div>
                            )
                        }else if (review.reviewer_rating == 5 ){
                            return ( <div>
                                <p className='p-0 m-0'> <span className='Review-Title'> {review.reviewer_name}  </span>
                            <span className='text-success'> <FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/>
                            <FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/></span>
                            </p> <p> {review.reviewer_comments}</p>
                            </div>
                            )
                        }

                        return (
                            <div>
                                <h6 className='mt-2'> Reviews </h6>
        
                                {MyView}
        
                            </div>
                        )
                });

                return (
                    <div>
                        <h6 className='mt-2'> Reviews </h6>

                        {MyView}

                    </div>
                )
            }else {
                return (
                    <div>
              
                          <h6 className='mt-2'> Reviews </h6>
              
                            <p> There are no reviews for this product.</p>
                    </div>
                  )
            }

    
  }
}

export default ReviewList
