import React, { Component } from 'react'
import { Fragment } from 'react'
import { Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import menuicon from '../../assets/images/picture.png';
import AppURL from '../../api/AppURL';

export class MegaMenu extends Component {


    constructor(props){
        super();
       
    }

    MenuItemClick = (event) => {
        event.target.classList.toggle("active");

        var panel = event.target.nextElementSibling;
                    if (panel.style.maxHeight){
                        panel.style.maxHeight = null;
                    }else {
                        panel.style.maxHeight = panel.scrollHeight + "px";
                       
                    }
    }
  render() {

    const catList  = this.props.data ;
    const myView = catList.map((catList, i) => {

        return <div key={i.toString()}>

                        <button key={catList.category_id} className='accordion' onClick={this.MenuItemClick}><img className='accordionMenuIcon' src={catList.category_image_path} /> {catList.category_name}</button>
                        <div className='panel'>
                                <ul>
                                    {
                                        (catList.subcategories).map((subList, i) => {
                                                return <li> <Link to={"productsubcategory/" + catList.category_name + "/" + subList.subcategory_name  } key={subList.id} className='accordionItem'> {subList.subcategory_name} </Link></li>

                                        })
                                    }
                                </ul>
                        </div>

        </div>

    });

    return (
      <div className='accordionMenuDiv'>
        <div className='accordionMenuDivInside'>
           
                {myView }
             </div>

      </div>
    )
  }
}

export default MegaMenu
