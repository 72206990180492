import { faBell } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import React, { Component, Fragment } from 'react'
import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap'
import { Navigate } from 'react-router'
import AppURL from '../../api/AppURL'

export class Notification extends Component {

    constructor (){
        super()
        this.state = {
            show: false,
            NotificationData: [],
            isLoading: '',
          mainDiv: 'd-none',
          NotificationMessage: '',
          NotificationTitle: '',
          NotificationDate: '',
        }
    }

    componentDidMount (){
        axios.get(AppURL.NotificationHistory).then(response => {
                if (response.status == 200 ){
                    this.setState({
                        NotificationData: response.data,
                        isLoading: 'd-none',
                        mainDiv: ''
                    });
                    
                }
        }).catch(error => {
                
        });
    }

    handleShow = (event) => {
        this.setState ({
                show: true
        });
        let Ntitle = event.target.getAttribute('data-title');
        let Nmessage = event.target.getAttribute('data-message');
        let Ndate = event.target.getAttribute('data-date');
        
        this.setState({
            NotificationMessage: Nmessage,
            NotificationTitle: Ntitle,
            NotificationDate: Ndate
        });
    }
    handleClose = () => {
        this.setState ({
            show: false
        })
    };
  render() {

    if (!localStorage.getItem('token')){
        return <Navigate to='/login' />
      }

    const NotificationList  = this.state.NotificationData ;
    
    const   MyView = NotificationList.map((NList, i ) => {

            return  <Col key={i} className='p-1' md={6} lg={6} sm={12} xs={12}>
            <Card className='notification-card' onClick={this.handleShow}>
                <Card.Body>
                    <h6> {NList.title} </h6>
                    <p className='py-1 px-0 text-primary m-0'>
                   {NList.message}  <FontAwesomeIcon icon={faBell} /> Date: {NList.date} | Status: Unread
                    </p>

                    <Button data-title={NList.title} data-date={NList.date} data-message={NList.message} className='btn btn-danger'> Details </Button>
                </Card.Body>
            </Card>
        </Col>

            });
      

    return (
        <Fragment>

        <Container className="TopSection">

            <Row>

            {MyView}


            </Row>
            </Container>


                <Modal show={this.state.show} onHide={this.handleClose}>
                <Modal.Header closeButton>
                <h6><FontAwesomeIcon icon={faBell}/> Date: {this.state.NotificationDate} </h6>
                </Modal.Header>
                <Modal.Body>
                <h6> {this.state.NotificationTitle}  </h6>
                <p>
                      {this.state.NotificationMessage} 
                </p>
                


                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                Close
                </Button>

                </Modal.Footer>
                </Modal>



        </Fragment>
    )
  }
}

export default Notification
