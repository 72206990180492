import React, { Component } from 'react'
import { Fragment } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap';
import AppURL from '../../api/AppURL';
import logo from '../../assets/images/app-icon.png';
import axios from 'axios';
import { Link } from 'react-router-dom';

export class Categories extends Component {

    constructor(){
        super();
    
        this.state = {
            MenuData: []
        }
      }
    
      componentDidMount(){
        axios.get(AppURL.AllCategoryDetails) . then (response => {
            
          this.setState({
              MenuData : response.data
          });
        }) . catch(error => {
    
        });
      }

  render() {

        const catList = this.state.MenuData;
        const myView = catList.map((catList, i) => {

            return  <Col key={i.toString()} className='p-0' xl={3} lg={3} md={3} sm={6} xs={6}>
                        <Link className='text-link' to={"/productcategory/" + catList.category_name}> 
                            <Card className='h-100 w-100 text-center'>
                            <img className='center' src={catList.category_image_path} />

                        <Card.Body>
                            <h5 className='category-name'>{catList.category_name}</h5>
                        </Card.Body>
                        </Card>
                        </Link>
                    </Col>
        })

    return (
        <Fragment>
            <Container>

            <div className='section-title text-center mb-55'> <h2> Categories </h2>
                    <p> some of our exclusive collection, You may like  </p>
                </div>
                <Row>
                    {myView}

                </Row>
            </Container>
        </Fragment>
    )
  }
}

export default Categories
