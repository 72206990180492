import React, { Fragment } from 'react'
import NavMenuDesktop from '../components/common/NavMenuDesktop'
import NavMenuMobile from '../components/common/NavMenuMobile'
import FooterDesktop from '../components/common/FooterDesktop'
import FooterMobile from '../components/common/FooterMobile'
import DeliveredOrders from '../components/orders/DeliveredOrders'
function DeliveredOrdersPage(props) {
  return (
    <Fragment>
        <div className='Desktop'>
          <NavMenuDesktop user={props.user} />
        </div>
        <div className='Mobile'>
        <NavMenuMobile />
        </div>
      
        <DeliveredOrders user={props.user}/>
        
        <div className='Desktop'>
        <FooterDesktop />
        </div>

        <div className='Mobile'>
          <FooterMobile />
        </div>
      </Fragment>
  )
}

export default DeliveredOrdersPage
