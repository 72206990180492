import axios from 'axios';
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import AppURL from '../../api/AppURL';

export class MegaMenuMobile extends Component {

    constructor(){
        super();
       this.MegaMenu = this.MegaMenu.bind(this);
       this.state = {
        MenuData: []
        }
    }
    componentDidMount(){
        this.MegaMenu();
        axios.get(AppURL.AllCategoryDetails).then(response => {
            
            this.setState({
                MenuData : response.data
            });
          }).catch(error => {
      
          });
    }
    MegaMenu(){
        var acc = document.getElementsByClassName('accordionMobile');
        var accNum = acc.length;
        var i ;
        for (i = 0; i < accNum; i ++ ){
            
            acc[i].addEventListener("click", function(){
                this.classList.toggle("active");
                var panel = this.nextElementSibling;
                    if (panel.style.maxHeight){
                        panel.style.maxHeight = null;
                    }else {
                        panel.style.maxHeight = panel.scrollHeight + "px";
                    }
            });
        }
    }

    MenuItemClick = (event) => {
        event.target.classList.toggle("active");

        var panel = event.target.nextElementSibling;
                    if (panel.style.maxHeight){
                        panel.style.maxHeight = null;
                    }else {
                        panel.style.maxHeight = panel.scrollHeight + "px";
                       
                    }
    }
    
  render() {

    const catList  = this.state.MenuData ;
    const myView = catList.map((catList, i) => {

        return <div key={i.toString()}>

                        <button key={catList.category_id} className='accordionMobile' onClick={this.MenuItemClick}><img className='accordionMenuIconMobile' src={ catList.category_image_path} alt="" /> {catList.category_name}</button>
                        <div className='panelMobile'>
                                <ul>
                                    {
                                        (catList.subcategories).map((subList, i) => {
                                                return <li> <Link to={"/productsubcategory/" + catList.category_name + "/" + subList.subcategory_name  } key={subList.id} className='accordionItemMobile'> {subList.subcategory_name} </Link></li>

                                        })
                                    }
                                </ul>
                        </div>

        </div>

    });
    return (
        <div className='accordionMenuDivMobile'>
        <div className='accordionMenuDivInsideMobile'>
           
           {myView}

        </div>
      
       

      </div>
    )
  }
}

export default MegaMenuMobile
