import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Fragment } from 'react'
import { Card, Col, Container, ListGroup, ListGroupItem, Row, Table } from 'react-bootstrap'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import AppURL from '../../api/AppURL';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';


function ProductsList(props) {

    var [product_list, setProductList] = useState([]);
    var [data, setData ] = useState([]);
    var [currentPage, setCurrentPage] = useState('');
    var [perPage, setPerPage] = useState('');
    var [total, setTotal] = useState('');
    var [totalPage, setTotalPage] = useState('');
    var [links, setLinks] = useState([]);

    const navigate = useNavigate();

    const editProduct = (event) => {
        event.preventDefault();
        
        navigate('/products/edit' , {state:{pid: event.target.getAttribute('data')}} )
    }

    useEffect(() => {
        axios.get(AppURL.AllProducts(props.user.email))
      .then(function(response){
        
        setProductList(response.data.products);
       
      })
      .catch(function(error){
        
      })
    },[]);

    const renderPagination = () => (
        <ul className="pagination">
            {
                links?.map((link, index) => (
                    <li key={index} className="page-item">
                        <a href="" className={`page-link ${link.active ? 'active': ''}`}>{link.label.replace('&laquo;', '').replace('&raquo;','')} </a>
                    </li>
                ))
            }
        </ul>
    )

    const deleteProduct = (e) => {
       
        axios.get(AppURL.deleteProduct(e.target.getAttribute('data')))
        .then(function(response){
            if (response.data.code === 1 ){
                toast.success(response.data.message, {
                    position: "top-center"
                  });
                  return <Navigate to='/products' />
            }
        }).catch(function(error){
                if (error.response.code === 0 ){
                    toast.success(error.response.error, {
                        position: "top-center"
                      });
                }
        });
    }

    const loadProducts = () =>{
        return ( product_list.map((record, key) => {
            return (
        
            <tr className='mt-2'>
            <td >{key + 1}</td>
            <td > <img src={record.product_image} height='60' width='60' /></td>
            <td >{record.title}</td>
            <td>{record.price}</td>
            <td>{record.special_price}</td>
            <td>{record.category}</td>
            <td>{record.subcategory}</td>
            <td>{record.brand}</td>
            <td>{record.remark}</td>
            <td>{record.product_code}</td>
            <td>{record.description}</td>
            <td><button className='btn btn-info' onClick={(e) => {alert('working')}}>view</button></td>
            <td><button className='btn btn-danger' data={record.id} onClick={deleteProduct}>delete</button></td>
            <td><button className='btn btn-primary' data={record.id} onClick={editProduct}>Edit</button></td>
            </tr>
                )
          })
          );
    }

  return (
    <Fragment>


    <div className='section-title text-center mb-55'> <h2> Your Products </h2>
                        
                        </div>
    <Container >
      <Row>
        <Col lg={4} md={4} sm={4} xs={4} >
    
          <Card style={{width: '18rem'}}>
            <Card.Img className='userprofile' variant='top' src={props.user.profile_photo_path} />
            <Card.Body>
              <Card.Title > Title </Card.Title>
    
            </Card.Body>
              <ListGroup className='list-group-flush'>
                <ListGroupItem><Link className='text-link' to='/orderlist'> <p className='product-name-on-card'>  Order List</p> </Link></ListGroupItem>
                <ListGroupItem className={props.user.type}><Link className={'text-link '+ props.user.type} to='/delieveredorders'> <p className='product-name-on-card'>  Delievered Orders</p> </Link></ListGroupItem>
                <ListGroupItem className={props.user.type}><Link className={'text-link '+ props.user.type} to='/shippedorders'> <p className='product-name-on-card'>  Shipped Orders</p> </Link></ListGroupItem>
                <ListGroupItem className={props.user.type}><Link className={'text-link '+ props.user.type} to='/processedorders'> <p className='product-name-on-card'>  Processed Orders</p> </Link></ListGroupItem>
                <ListGroupItem className={props.user.type}><Link className={'text-link '+ props.user.type} to='/neworders'> <p className='product-name-on-card'>  New Orders</p> </Link></ListGroupItem>
                <ListGroupItem className={props.user.type}><Link className={'text-link '+ props.user.type} to='/addproduct'> <p className='product-name-on-card'>  Add Products</p> </Link></ListGroupItem>
                <ListGroupItem className={props.user.type}><Link className={'text-link '+ props.user.type} to='/products'> <p className='product-name-on-card'>  Products</p> </Link></ListGroupItem>
              
              </ListGroup>
             
    
          </Card>
          
        </Col>
        <Col lg={8} md={8} sm={12} xs={12} >
        

            <ul className='list-group mt-3'>
            <table striped bordered hover size="sm">
            <tr>
                <th>No #</th>
                <th>Icon Image</th>
                <th>Title</th>
                <th>Price</th>
                <th>Special Price</th>
                <th>Category</th>
                <th>Subcategory</th>
                <th>Remark</th>
                <th>Brand</th>
                <th>Product Code</th>
                <th>description</th>
                <th> Action </th>
            </tr>
            <tbody>
                {loadProducts()}
                
            </tbody>
           </table>
            </ul>


      
        </Col> 

        

        </Row>
    </Container>
    
            <ToastContainer theme="dark" />
          </Fragment>
  )
}

export default ProductsList
