import axios from 'axios';
import React, { Component, Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router';
import AppURL from '../api/AppURL';
import NavMenuDesktop from '../components/common/NavMenuDesktop';
import NavMenuMobile from '../components/common/NavMenuMobile';
import SearchList from '../components/ProductDetails/SearchList';
import FooterDesktop from '../components/common/FooterDesktop';
import FooterMobile from '../components/common/FooterMobile';
export function SearchPage () {


    const params = useParams();
    var [SearchKey, setSearchKey] = useState();
    var [productdata, setProductData] = useState([]);

    useEffect(() => {
        setSearchKey(params.searchKey);
        //console.log(props);
        window.scroll(0,0);
        //alert(this.state.category);

        axios.get(AppURL.ProductSearch(SearchKey)).then(
              response => {
                  // this.setState({ProductData: response.data});
                  setProductData(response.data);
              }
        ).catch(
            error => {

            }
        );
    });


 
    return (
        <>
        <Fragment>
        <div className='Desktop'>
          <NavMenuDesktop />
        </div>
        <div className='Mobile'>
        <NavMenuMobile />
        </div>
      
        <SearchList search_key={SearchKey} ProductData={productdata} />
        
        <div className='Desktop'>
        <FooterDesktop />
        </div>

        <div className='Mobile'>
          <FooterMobile />
        </div>
      </Fragment>
      </>
    )

}

export default SearchPage
