import React from 'react'
import { Fragment } from 'react'
import NavMenuDesktop from '../components/common/NavMenuDesktop'
import NavMenuMobile from '../components/common/NavMenuMobile'
import FooterDesktop from '../components/common/FooterDesktop';
import FooterMobile from '../components/common/FooterMobile';
import ProductEdit from '../components/ProductDetails/ProductEdit'
import { useLocation } from 'react-router';

function ProductEditPage(props) {
    const User = props.user;
    const {state} = useLocation();
  return (
    <Fragment>
    <div className='Desktop'>
      <NavMenuDesktop user={User}/>
    </div>
    <div className='Mobile'>
    <NavMenuMobile />
    </div>
  
    <ProductEdit user={User} data={state}/>
    
    <div className='Desktop'>
    <FooterDesktop />
    </div>

    <div className='Mobile'>
      <FooterMobile />
    </div>
  </Fragment>
  )
}

export default ProductEditPage
