import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faFacebook, faFacebookF, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Apple from '../../assets/images/apple.png'
import Google from '../../assets/images/google.png'
import AppURL from '../../api/AppURL';
import axios from 'axios';
import parse from 'html-react-parser';

export class footerDesktop extends Component {

  constructor(){
    super();

    this.state = {
        address : '',
        twitter_link: '',
        android_app_link: '',
        ios_app_link: '',
        facebook_link: '',
        copyright_text: '',
        instagram_link: '',
        linkedin_link: '',
        loaderDiv: '',
        mainDiv: 'd-none'

    }

     
}
componentDidMount (){
    axios.get(AppURL.AllSiteInfo).then(response => {

            if (response.status == 200 ){

                this.setState({
                    address: (response.data)['address'],
                    twitter_link: (response.data)['twitter_link'],
                    android_app_link: (response.data)['android_app_link'],
                    ios_app_link: (response.data)['ios_app_link'],
                    facebook_link: (response.data)['facebook_link'],
                    copyright_text: (response.data)['copyright_text'],
                    instagram_link: (response.data)['instagram_link'],
                    linkedin_link: (response.data)['linkedin_link'],
                    loaderDiv: 'd-none',
                    mainDiv: ''
                });
            }

    }).catch(error => {
            
    });
}
  render() {
    return (
      <Fragment >

        <div className='footerback m-0 mt-5 pt-3 shadow-sm'>
          <Container >
              <Row className='px-0 my-5' lg={3} md={3} sm={6} xs={12}>
                  <Col className='p-2' lg={3} md={3} sm={6} xs={12}>

                  <div className={this.state.loaderDiv}>
                            <div class="ph-item">
                                <div class="ph-col-12">
                                    <div class="ph-picture"></div>
                                    <div class="ph-row">
                                        <div class="ph-col-6 big"></div>
                                        <div class="ph-col-4 empty big"></div>
                                        <div class="ph-col-2 big"></div>
                                        <div class="ph-col-4"></div>
                                        <div class="ph-col-8 empty"></div>
                                        <div class="ph-col-6"></div>
                                        <div class="ph-col-6 empty"></div>
                                        <div class="ph-col-12"></div>
                                    </div>
                                </div>
                            </div>
                      </div>


                    <div className={this.state.mainDiv}>
                        <h5 className='footer-menu-title'> Office Address </h5>
                        <p> {parse(this.state.address)}
                        </p>
                        <h5 className='footer-menu-title'> Social Link </h5>
                        <a href={this.state.facebook_link}><FontAwesomeIcon className='m1 h4' icon={faFacebook} /> </a>
                        <a href={this.state.instagram_link}><FontAwesomeIcon className='m1 h4' icon={faInstagram} /> </a>
                        <a href={this.state.twitter_link}><FontAwesomeIcon className='m1 h4' icon={faTwitter} /> </a>
                        <a href={this.state.linkedin_link}><FontAwesomeIcon className='m1 h4' icon={faLinkedin} /> </a>
                        </div>
                  </Col>
                  <Col className='p-2' lg={3} md={3} sm={6} xs={12}>
                  <h5 className='footer-menu-title'> The Company </h5>
                  <Link to='/about' className='footer-link'> About Us </Link> <br></br>
                  <Link to='/' className='footer-link'> Company Profile </Link> <br></br>
                  <Link to='/contact' className='footer-link'> Contact Us</Link> <br></br>
                  
                  </Col>

                  <Col className='p-2' lg={3} md={3} sm={6} xs={12}>
                  <h5 className='footer-menu-title'> More Info </h5>
                  <Link to='/purchase' className='footer-link'> How To Purchase </Link> <br></br>
                  <Link to='/privacy' className='footer-link'> Privacy Policy </Link> <br></br>
                  <Link to='/refund' className='footer-link'> Refund Policy </Link> <br></br>
                  </Col>

                  <Col className='p-2' lg={3} md={3} sm={6} xs={12}>
                  <h5 className='footer-menu-title'> Download App</h5>
                  <a href={this.state.ios_app_link}><img src={Apple} /></a><br></br>
                  <a href={this.state.android_app_link}><img src={Google} className='mt-2' /></a><br></br>
                  Change your language <br></br>

                  <div id="google_translate_element"></div>
                  </Col>
              </Row>
          </Container>

            <Container fluid={true} className='text-center m-0 pt-3 pb-1 bg-dark'>
                <Container>
                    <Row>
                      <h6 className='text-white'>{parse(this.state.copyright_text)}</h6>
                    </Row>
                </Container>

            </Container>
          </div>
      </Fragment>
    )
  }
}

export default footerDesktop
