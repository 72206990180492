
import React, { Component, Fragment } from 'react'
import { Breadcrumb, Col, Container, Row } from 'react-bootstrap'
import AppURL from '../../api/AppURL';
import axios from 'axios';
import parse from 'html-react-parser'
import { Link } from 'react-router-dom';

export class Refund extends Component {

  constructor(){
    super();

    this.state = {
        refund : '',
        loaderDiv: '',
        mainDiv: 'd-none'

    }

     
}
componentDidMount (){
    axios.get(AppURL.AllSiteInfo).then(response => {

            if (response.status == 200 ){

                //alert((response.data)['about']);
                this.setState({
                    refund: (response.data)['refund'],
                    loaderDiv: 'd-none',
                    mainDiv: ''
                });
            }

    }).catch(error => {
            
    });
}
  render() {
    return (
        <Fragment>
        <Container>

        <div className='breadbody'>
        <Breadcrumb>
      <Breadcrumb.Item > <Link to="/">Home</Link> </Breadcrumb.Item>
      
      <Breadcrumb.Item ><Link to="/refund">Refund</Link></Breadcrumb.Item>
    </Breadcrumb>
    </div>

            <Row className='p-2'>
                <Col className='shadown-sm bg-white mt-2' lg={12} md={12} sm={12} xs={12}>


                <div className={this.state.loaderDiv}>
                            <div class="ph-item">
                                <div class="ph-col-12">
                                    <div class="ph-picture"></div>
                                    <div class="ph-row">
                                        <div class="ph-col-6 big"></div>
                                        <div class="ph-col-4 empty big"></div>
                                        <div class="ph-col-2 big"></div>
                                        <div class="ph-col-4"></div>
                                        <div class="ph-col-8 empty"></div>
                                        <div class="ph-col-6"></div>
                                        <div class="ph-col-6 empty"></div>
                                        <div class="ph-col-12"></div>
                                    </div>
                                </div>
                            </div>
                      </div>

                      <div className={this.state.mainDiv}>
                    <h4 className='section-title-login'> Refund Page </h4>
                    <p className='section-title-contact'>
                      {parse(this.state.refund)}
                     </p>
                     </div>
                </Col>
            </Row>
        </Container>
    </Fragment>
    )
  }
}

export default Refund
