import React, { Component, Fragment } from 'react'
import { Button, Col, Container, Navbar, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import bar from '../../assets/images/menu-bar.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faHome, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import MegaMenuMobile from '../home/MegaMenuMobile';

export class NavMenuMobile extends Component {

  constructor(){
    super();
    this.state = {
      SideNavState : 'sideNavClose',
      ContentOverState: 'ContentOverlayClose'
    }
  }

  SideNavOpenClose = () => {
    let SideNavState = this.state.SideNavState;
    let ContentOverState = this.state.ContentOverState;
    if (SideNavState === "sideNavOpen"){
      this.setState({SideNavState: "sideNavClose", ContentOverState: "ContentOverlayClose"})
    }else {
      this.setState({SideNavState: "sideNavOpen", ContentOverState: "ContentOverlayOpen"})
    }
  }

  MenuBarClickHandler = () => {
    this.SideNavOpenClose();


  }

  ContentOverlayClickHandler = () => {
    this.SideNavOpenClose();
  }
  render() {
    return (
      <Fragment >
            <div className='TopSectionDown'>
      
                <Container fluid={true} className='fixed-top shadow-sm p-2 mb-0 bg-white'>

              
                  <Row>
                    <Col lg={4} sm={12} md={4} xs={12}>
                    
                    <img onClick={this.MenuBarClickHandler} className='bar-img' src={bar}/>
                    <Link to='/'> <img src={logo} alt="" className='nav-logo'  /></Link>
                    <Button className='cart-btn'> <FontAwesomeIcon icon={faShoppingCart}/> 3 items</Button>
                    
                    </Col>
                  
                  </Row>
                </Container>

                <div className={this.state.SideNavState}>
                  <MegaMenuMobile />
                </div>

                  <div onClick={this.ContentOverlayClickHandler} className={this.state.ContentOverState}>
                    
                  </div>
       
            </div>

      </Fragment>
    )
  }
}

export default NavMenuMobile
