import React from 'react'
import { Fragment } from 'react'
import NavMenuDesktop from '../components/common/NavMenuDesktop'
import NavMenuMobile from '../components/common/NavMenuMobile'
import ProductsList from '../components/ProductDetails/ProductsList'
import FooterDesktop from '../components/common/FooterDesktop';
import FooterMobile from '../components/common/FooterMobile';

function ProductListPage(props) {
    const User = props.user;
  return (
    <Fragment>
        <div className='Desktop'>
          <NavMenuDesktop user={User}/>
        </div>
        <div className='Mobile'>
        <NavMenuMobile />
        </div>
      
        <ProductsList user={User}/>
        
        <div className='Desktop'>
        <FooterDesktop />
        </div>

        <div className='Mobile'>
          <FooterMobile />
        </div>
      </Fragment>
  )
}

export default ProductListPage
