import { faCar, faHeart, faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component, Fragment, useEffect } from 'react'
import { Breadcrumb, Col, Container, Row } from 'react-bootstrap'
import * as ReactDOM from 'react-dom'
import { Link, Navigate } from 'react-router-dom'
import SuggestedProduct from './SuggestedProduct'
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import InnerImageZoom from 'react-inner-image-zoom';
import { useState } from 'react'
import ReviewList from './ReviewList'
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios'
import AppURL from '../../api/AppURL'

export function ProductDetails (props) {
  
          let ProductAllData = props.data;
          let title = '';
          let p_id = '';
          let category = '';
          let price = '';
          let p_code = '';
          let remark = '';
          let sprice = '';
          let star_mark = '';
          let prod_brand = '';
          let sub_category = '';
          let short_desc = '';
          let pcolor = '';
          let psize = '';
          let pbrand = '';
          let description = '';
          var Images = '';
          var BigImage = '';

          
          var [preveiwImg, setPreviewImg] = useState('0');
          var [isSize, setisSize] = useState(null);
          var [isColor, setisColor] = useState(null);
          var [color, setColor] = useState('');
          var [size, setSize] = useState('');
          var [quantity, setQuantity] = useState('');
          var [productCode, setProductCode] = useState(null);
          var [addToCart, setAddToCart] = useState('Add To Cart');
          var [contact_number, setContact] = useState(null);
          var [pageRefreshStatus, setPageRefreshStatus] = useState(false);
          var [addToFavourite, setAddToFavourite] = useState('Favourite');
          var [ProductSubCategory, setProductSubCategory] = useState('');
          var [orderNow, setOrderNow] = useState('Order Now');
        

          const imgOnClick = (event) => {
               
               let imgSrc = event.target.src;
               setPreviewImg(imgSrc);
                let previewImg = document.querySelector('.iiz__img   ').src= event.target.src;
                alert(document.querySelector('.iiz__img   ').getAttribute('zoomSrc'));
                document.querySelector('.iiz__img   ').setAttribute('zoomSrc', event.target.src);
               // previewImg.src = event.target.src;
                // previewImg.setAttribute('src', imgSrc);
          }

          const AddToCart = () => {
               let is_Size = isSize;
               let is_Color = isColor;
               let prod_size = size;
               let prod_color = color;
               let prod_quantity = quantity;
               let productCode = p_code;
               let email = null;
               let contact_no = contact_number;
               let registered_email = props.user.email;
               
               if (is_Color === 'YES'  && color.length === 0 ){
                    toast.error('Please Select Color',{position: 'top-right'});
               }else if (is_Size === 'YES'  && prod_size.length === 0){
                    toast.error('Please Select Size',{position: 'top-right'});
               }else if ( quantity.length === 0){
                    toast.error('Please Select Quantity',{position: 'top-right'});
               }else if (contact_no === null ){
                    toast.error('Please Provide Correct Contact information',{position: 'top-right'});
               }else if (!localStorage.getItem('token')){
                    toast.error('Please Login first To Complete your order.',{position: 'top-right'});
               }else {
                         setAddToCart('adding....');
                         let email = props.user.email;
                         let MyFormData = new FormData();
                         MyFormData.append('color', prod_color);
                         MyFormData.append('size', prod_size);
                         MyFormData.append('quantity', prod_quantity);
                         MyFormData.append('product_code', productCode);
                         MyFormData.append('email', email);
                         MyFormData.append('contact_no', contact_no);
                         MyFormData.append('registered_email', registered_email);
                         
                         axios.post(AppURL.AddToCart, MyFormData).then(response => {
                              if (response.status === 200 ){
                                  if (response.data.code === 1 ){
                                   toast.success(response.data.message,{position: 'top-right'});
                                   
                                   setPageRefreshStatus(true);
                                  }else {
                                   toast.error(response.data.message,{position: 'top-right'});
                                  }
                              }
                              setAddToCart('Add To Cart');
                      }).catch(error => {
                         toast.error(error.message,{position: 'top-right'});
                         setAddToCart('Add To Cart');
                      });
                      setAddToCart('Add To Cart');
               }

          }

          const sendForOrderNow = () => {
               let is_Size = isSize;
               let is_Color = isColor;
               let prod_size = size;
               let prod_color = color;
               let prod_quantity = quantity;
               let productCode = p_code;
               let email = null;
               let contact_no = contact_number;
               let registered_email = props.user.email;
               
               if (is_Color === 'YES'  && color.length === 0 ){
                    toast.error('Please Select Color',{position: 'top-right'});
               }else if (is_Size === 'YES'  && prod_size.length === 0){
                    toast.error('Please Select Size',{position: 'top-right'});
               }else if ( quantity.length === 0){
                    toast.error('Please Select Quantity',{position: 'top-right'});
               }else if (contact_no === null ){
                    toast.error('Please Provide Correct Contact information',{position: 'top-right'});
               }else if (!localStorage.getItem('token')){
                    toast.error('Please Login first To Complete your order.',{position: 'top-right'});
               }else {
                         setOrderNow('adding....');
                         let email = props.user.email;
                         let MyFormData = new FormData();
                         MyFormData.append('color', prod_color);
                         MyFormData.append('size', prod_size);
                         MyFormData.append('quantity', prod_quantity);
                         MyFormData.append('product_code', productCode);
                         MyFormData.append('email', email);
                         MyFormData.append('contact_no', contact_no);
                         MyFormData.append('registered_email', registered_email);
                         
                         axios.post(AppURL.AddToCart, MyFormData).then(response => {
                              if (response.status === 200 ){
                                  if (response.data.code === 1 ){
                                   toast.success(response.data.message,{position: 'top-right'});
                                   
                                   setPageRefreshStatus(true);
                                  }else {
                                   toast.error(response.data.message,{position: 'top-right'});
                                  }
                              }
                              setOrderNow('Order Now');
                      }).catch(error => {
                         toast.error(error.message,{position: 'top-right'});
                         setOrderNow('Order Now');
                      });
                      setOrderNow('Order Now');
               }

          }

          const PageRedirect = () => {
               if (pageRefreshStatus){
                    return(
                         <Navigate to='/cart' />
                    )
               }
          }

          const addToFav = () => {
               setAddToFavourite('Adding....');

               let prod_code = p_code;
               let user_email = props.user.email;

               if (!localStorage.getItem('token')){
                    toast.error(' Please first Login to your account to proceed.',{position: 'top-right'});
                    setAddToFavourite('Favourite');
               }else {
                    axios.get(AppURL.AddFavourite(prod_code, user_email)). then(response => {
                         if (response.status === 200 ){
                              if (response.data.code === 1 ){
                                   toast.success(response.data.message, {position: 'top-right'});
                                   setAddToFavourite('Favourite');
                              }else {
                                   toast.error(response.data.message, {position: 'top-right'});
                                   setAddToFavourite('Favourite');
                              }
                              
                         }
                         
                    }).catch(error => {
                         toast.error(error.message, {position: 'top-right'});
                         setAddToFavourite('Favourite');
                    });
               }
               

          } // End Add to Favourite 

          const colorOnChange = (event) => {
               let color = event.target.value;
               setColor(color);
          }

          const sizeOnChange = (event) => {
               
               let size = event.target.value;
               setSize(size);
               
          }

          const quantityOnChange = (event) => {
               let quanity = event.target.value;
               setQuantity(quanity);
               
          }

          const contactInfoOnChange = (event) => {
               let customer_contact = event.target.value;
               setContact(customer_contact);
          }

          const PageRefresh=() => {
               if ( pageRefreshStatus ){
                    let URL = window.location;
                    return (
                              <Navigate to={URL} />
                    )
               }
          }


          useEffect(() => {
               ProductAllData = props.data;
               if (ProductAllData.length > 0 ){
                    title = ProductAllData['productList']['0']['title'];
               p_id = ProductAllData['productList']['0']['id'];
               category = ProductAllData['productList']['0']['category'];
               price = ProductAllData['productList']['0']['price'];
               p_code = ProductAllData['productList']['0']['product_code'];
               remark = ProductAllData['productList']['0']['remark'];
               sprice = ProductAllData['productList']['0']['special_price'];
               star_mark = ProductAllData['productList']['0']['star'];
               prod_brand = ProductAllData['productList']['0']['brand'];
               sub_category = ProductAllData['productList']['0']['subcategory'];
               short_desc = ProductAllData['productDetails']['product_short_description'];
               pcolor = ProductAllData['productDetails']['product_color'];
               psize = ProductAllData['productDetails']['product_size'];
               pbrand = ProductAllData['productDetails']['product_brand'];
               description = ProductAllData['productDetails']['product_description'];
               setProductSubCategory(ProductAllData['productList']['0']['subcategory']);
                    
               }
               
          },[props.data]);

          const priceOption = (price, specialPrice) => {
               if (specialPrice == '0.00'){
                    
                    return (
                         <p className='product-name-on-card'> Price : $ {price} </p>
                    )

               }else {
                         return (
                              <p className='product-name-on-card'> <strike className='text-secondary'> Price: $ {price} </strike> Special Price : $ {specialPrice}</p>
                         )
               }
          }

          if (ProductAllData['productList'] === undefined ){
               
          }else {
           title = ProductAllData['productList']['0']['title'];
           p_id = ProductAllData['productList']['0']['id'];
           category = ProductAllData['productList']['0']['category'];
           price = ProductAllData['productList']['0']['price'];
           p_code = ProductAllData['productList']['0']['product_code'];
           remark = ProductAllData['productList']['0']['remark'];
           sprice = ProductAllData['productList']['0']['special_price'];
           star_mark = ProductAllData['productList']['0']['star'];
           prod_brand = ProductAllData['productList']['0']['brand'];
           sub_category = ProductAllData['productList']['0']['subcategory'];
           short_desc = ProductAllData['productDetails']['product_short_description'];
           pcolor = ProductAllData['productDetails']['product_color'];
           psize = ProductAllData['productDetails']['product_size'];
           pbrand = ProductAllData['productDetails']['product_brand'];
           description = ProductAllData['productDetails']['product_description'];
           Images = '';
     
           BigImage = ProductAllData['productImages'].map((image1, i) => { 
              
               if (i === 0 ){
                         
                    return  ( 
                  <div>  <InnerImageZoom className='detailimage' id='detailimage' zoomScale={2.5} zoomType='hover' src={image1.product_image_path} zoomSrc={image1.product_image_path} /> </div>
                  
                  )
               }
               return 
          });
     
          Images =  ProductAllData['productImages'].map((images, i) => {
                  
                    return  <Col className="p-0 m-0"  md={3} lg={3} sm={3} xs={3}> 
                         <img onClick={imgOnClick} className="w-100 smallimage product-sm-img" key={i} src= {images.product_image_path} alt="small image" /> 
                         </Col>
                         });

                    }

          
          var ColorDiv = "d-none";
          if (pcolor !== ""){
               let ColorArray = pcolor.split(',');
               var ColorOption = ColorArray.map((ColorList, i) => {
                    return <option value={ColorList}> {ColorList} </option>
               });
               ColorDiv = ""
          }else {
               ColorDiv = 'd-none'
          }


          var SizeDiv = "d-none";
          if (psize !== ""){
               let SizeArray = psize.split(',');
               var SizeOption = SizeArray.map((SizeList, i) => {
                    return <option value={SizeList}>{SizeList}</option>
               });
               SizeDiv = ""
          }else {
               SizeDiv = 'd-none'
          }
    
          if (isColor === null ){
               if (color !== null ){
                    setisColor('YES');
               }else {
                    setisColor('NO');
               }
          }

          if (isSize === null ){
               if (size !== null ){
                    setisSize('YES');
               }else {
                    setisColor('NO');
               }
          }

          if (productCode === null ){
               setProductCode(p_code);
          }
 
    return (
        <Fragment>
               <Container fluid={true}  className="BetweenTwoSection">

               <div className='breadbody'>
                    <Breadcrumb>
                    <Breadcrumb.Item > <Link to="/">Home</Link> </Breadcrumb.Item>
                    <Breadcrumb.Item ><Link to={"/productcategory/" +category} >{category}</Link></Breadcrumb.Item>
                    <Breadcrumb.Item ><Link to={"/productlistbysubcategory/" +category + "/" + sub_category} >{sub_category}</Link></Breadcrumb.Item>
                    <Breadcrumb.Item ><Link to={"/productdetails/" +p_id } >{title}</Link></Breadcrumb.Item>
               </Breadcrumb>
               </div>

                   <Row className="p-2">
<Col className="shadow-sm bg-white pb-3 mt-4" md={12} lg={12} sm={12} xs={12}>
     <Row>
          <Col className="p-3" md={6} lg={6} sm={12} xs={12}>
          
               {BigImage}
         
          <Container  className="my-3">

               <Row>
                    {Images}
               </Row>
          </Container>
          </Col>
          <Col className="p-3 " md={6} lg={6} sm={12} xs={12}>
          <h5 className="Product-Name"> {title}  </h5>
          <h6 className="section-sub-title"> {short_desc} </h6>
          


          <h6 className="mt-2">Category : <b> {category} </b>  </h6>          

          <h6 className="mt-2">SubCategory :  <b> {sub_category} </b></h6>

          <h6 className="mt-2">Brand : <b> {prod_brand} </b></h6>

          <h6 className="mt-2">Product Code : <b> {p_code}</b></h6>
               
               <div className='input-group'>

                    {priceOption(price, sprice)}
                    
               </div>
               <div >
               <h6 className="mt-2" > Choose Color  </h6>
               <select onChange={colorOnChange} className="form-control form-select">
               <option>Choose Color</option>
               {ColorOption}
              
               </select> 
               </div>


               <div>
               <h6 className="mt-2"> Choose Size  </h6>
               <select onChange={sizeOnChange} className="form-control form-select">
               <option>Choose Size</option>
               {SizeOption}
               
               </select> 
               </div>

               <div className="" >
               <h6 className="mt-2"> Choose Quantity  </h6>
               <select onChange={quantityOnChange}  className="form-control form-select">
               <option>Choose Quantity</option>
               <option value="01">01</option>
               <option value="02">02</option>
               <option value="03">03</option>
               <option value="04">04</option>
               <option value="05">05</option>
               <option value="06">06</option>
               <option value="07">07</option>
               <option value="08">08</option>
               <option value="09">09</option>
               <option value="10">10</option> 
                
               </select> 
               </div>

               <div className='mt-2'>

                    <h6> Contact No</h6>
                    <input onChange={contactInfoOnChange} type='text' className='form-control' placeholder='Type your contact no'/>

               </div>
          

          <div className="input-group mt-3">

    <button  className="btn site-btn m-1 " onClick={AddToCart}> <FontAwesomeIcon icon={faShoppingCart} /> {addToCart}   </button>

 <button  className="btn btn-primary m-1" onClick={sendForOrderNow}> <FontAwesomeIcon icon={faCar} /> {orderNow} </button>
               
               <button  className="btn btn-primary m-1" onClick={addToFav}> <FontAwesomeIcon icon={faHeart} /> {addToFavourite}  </button>
          </div>
          </Col>
     </Row>

     <Row>
          <Col className="" md={6} lg={6} sm={12} xs={12}>
          <h6 className="mt-2">DETAILS</h6>
          <p> {description} </p>
          </Col>

          <Col className="" md={6} lg={6} sm={12} xs={12}>

          <ReviewList code={p_code} />
 
          </Col>
     </Row>

</Col>
                   </Row>

                   <Toaster />
               </Container>
              
                
              <SuggestedProduct subcategory={ProductSubCategory} />
               {PageRefresh()}
               {PageRedirect()}
               
               </Fragment>
    )
  
}

export default ProductDetails
