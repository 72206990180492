import axios from 'axios';
import React, { Component, Fragment } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { Link, Navigate } from 'react-router-dom'
import AppURL from '../../api/AppURL';
import Login from '../../assets/images/login.png'

export class Register extends Component {

    constructor(){
        super();
        this.state = {
            name :  '',
            password : "",
            password_confirmation: '',
            email : "",
            message : "",
            value : 'Personal',
            loggedin: false
        }
    }

     // Register Form Submit Method 
     FormSubmit = (e) => {
        e.preventDefault();

        const data = {
            name: this.state.name,
            email: this.state.email,
            password: this.state.password,
            type: this.state.value,
            password_confirmation: this.state.password_confirmation
        }

        axios.post(AppURL.UserRegister, data ).then(response => {

            localStorage.setItem('token', response.data.token);
            this.setState({message: response.data.message, loggedin: true });
            this.props.setUser(response.data.user);

        }).catch(error => {

        });
    }

  render() {
    if (this.state.loggedin){
        return <Navigate to={'/profile'} />
    }
    if (localStorage.getItem('token')){
        return <Navigate to='/profile' />
      }

    return (
        <Fragment>
        <Container>
            <Row className='p-2'>
                <Col className='shadown-sm bg-white mt-2' lg={12} md={12} sm={12} xs={12}>

                    <Row className='text-center'>
                        <Col className='d-flux justify-content-center'  lg={6} md={6} sm={12} xs={12}>
                        
                            <Form className='onboardForm' onSubmit={this.FormSubmit}>
                                <h4 className='section-title-login'>  User Register</h4>
                                <h6 className='section-sub-title'></h6>
                                <h6 > Please Enter Your Email </h6>


                                <input onChange={(e) => {this.setState({name: e.target.value})}} className='form-control m-2' type='text' placeholder='Please enter your name' />
                                <input onChange={(e) => {this.setState({email: e.target.value})}} className='form-control m-2' type='email' placeholder='Please type your email' />
                                <input onChange={(e) => {this.setState({password: e.target.value})}} className='form-control m-2' type='password' placeholder='Please type your password' />

                                <input onChange={(e) => {this.setState({password_confirmation: e.target.value})}} className='form-control m-2' type='password' placeholder='Please confirm password' />
                                <Form.Select onChange={(e) => {this.setState({value: e.target.value})}} value={this.state.value} className='form-control m-2'>
                                    <option value='Personal'>Personal</option>
                                    <option value='Business'>Business</option>
                                </Form.Select>
                                <Button type='submit' className='btn btn-blok m-2 site-btn-login'> Sign Up </Button>
                                <br></br> <br></br>
                                <hr></hr>

                                <p> <b> Forgot my Password ? </b> <Link to='/forget'> <b>Forgot Password</b> </Link></p>

                                <p> <b> Already Have an account ? </b> <Link to='/login'> <b>Login</b> </Link></p>
                            </Form>
                        </Col>
                        
                        <Col className='p-0 Desktop m-0' lg={6} md={6} sm={6} xs={6}>

                            <img className='onboardBanner'  src={Login} />
                        
                        </Col>
                        
                    </Row>
                
                </Col>
            </Row>
        </Container>
    </Fragment>
    )
  }
}

export default Register
