import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';
import React, { Component, Fragment } from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import AppURL from '../../api/AppURL';
import toast, { Toaster } from 'react-hot-toast';
import { Navigate } from 'react-router';

export class Favourite extends Component {


    constructor(){
        super();
    
        this.state = {
            ProductData: [],
            isLoading: '',
          mainDiv: 'd-none'
        }
      }

      componentDidMount (){
        axios.get(AppURL.FavouriteList(this.props.user.email)).then(response => {
                if (response.status == 200 ){
                    this.setState({
                        ProductData: (response.data),
                        isLoading: 'd-none',
                        mainDiv: ''
                    });
                }
        }).catch(error => {
                
        });
    }

     removeItem = (event) => {
        let product_code = event.target.getAttribute('data-code');
        let email = this.props.user.email ;

        axios.get(AppURL.DeleteFavourite(product_code, email)). then(response => {
            toast.success('Product Item Successfully Removed.', {position: 'top-right'});
        }).catch(error => {
            toast.error(error.data, {position: 'top-right'});
        });
    }

  render() {
    const FavList  = this.state.ProductData ;

    const MyView = FavList.map((ProductList, i ) => {

        return (
            <Col key={i} className='p-0' xl={3} lg={3} md={3} sm={6} xs={6} >

                        <Card className='image-box card w-100'>
                            <img className='center w-75' src={ProductList.product_image} width="20" height="20"/>
                
                            <Card.Body>
                                <p className='product-name-on-card'> {ProductList.product_name} </p>
                                
                                <Button onClick={this.removeItem} className='btn btn-sm' data-code={ProductList.product_code}> <FontAwesomeIcon icon={faTrashAlt}/> Remove </Button>
                            
                            </Card.Body>
                        </Card>
                    
                    </Col>
        )

     });

     if (!localStorage.getItem('token')){
        return <Navigate to='/login' />
      }
     
    return (
        <Fragment>
        <Container className='text-center' fluid={true}>

        <div className='section-title text-center mb-55'> <h2> My Favourite Items</h2>
                    <p> some of our exclusive collection, You may like </p>
                </div>
                <Row>   

                    {MyView}
                    

                </Row>
                <Toaster />
            </Container>
      </Fragment>
    )
  }
}

export default Favourite
