import axios from 'axios'
import React, { useRef, useState } from 'react'
import { Fragment } from 'react'
import { Card, Col, Container, ListGroup, ListGroupItem, Row } from 'react-bootstrap'
import { Navigate, useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import AppURL from '../../api/AppURL'
import Pic1 from '../../assets/images/pic1.jpg';
import { WithContext as ReactTags } from 'react-tag-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faKey } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ProductEdit(props) {
    
    var [title,setTitle] = useState(null);
    var [productCode, setProductCode] = useState(null);
    var [category, setCategory] = useState([]);
    var [subcategory, setSubcategory] = useState([]);
    var [price,setPrice] = useState();
    var [special_price,setSpecialPrice] = useState('00.00');
    var [currency,setCurrency] = useState()
    var [productImage,setProductImage] = useState();
    var [productCategory,setProductCategory] = useState();
    var [remark,setRemark] = useState();
    var [brand,setBrand] = useState();
    var [start,setStar] = useState();
    var [categoryID,setCategoryID] = useState();
    var [subCategoryID,setSubCategoryID] = useState();
    var [Images,setImages] = useState([

    ]);
    var [productDescription,setProductDescription] = useState();
    var [description,setDescription] = useState();
    var [shortDescription,setShortDescription] = useState();
    var [colors,setColors] = useState([

    ]);
    var [sizes,setSizes] = useState([]);
    var [brand,setBrand] = useState();
    var [icon, setIcon] = useState();


    const {state} = useLocation();


    useEffect(()=> {
      axios.get(AppURL.AllCategoryDetails)
      .then(function(response){
        
        setCategory(response.data);
       
      })
      .catch(function(error){
        console.log(error);
      })
    }, []);

    useEffect( () => {
            axios.get(AppURL.getProduct(state.pid)).then(function(response){

                console.log(response.data.code);

                if ( response.data.code === 1 ){
                    setTitle(response.data.result.title);
                    setProductCode(response.data.result.product_code);
                    setIcon(response.data.result.product_image);
                    setPrice(response.data.result.price);
                    setSpecialPrice(response.data.result.special_price);
                    setCurrency(response.data.result.currency);
                    setBrand(response.data.result.brand);
                    setRemark(response.data.result.remark);
                    setShortDescription(response.data.details.product_short_description);
                    setDescription(response.data.details.product_description);
                    console.log(response.data.details.product_size);

                }

            }).catch(function(error){

            });
    }, []);
   
    const generateProductCode = (e) => {
        axios.get(AppURL.GenerateProductcode)
            .then(function (response) {
                setProductCode(response.data.product_code);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    }

    const setIconImage = (e) => {
        
        setIcon(URL.createObjectURL(e.target.files[0]));
        setProductImage(e.target.files[0]);
        
    }

    const loadSubcategory = (e) => {
        
        axios.get(AppURL.findSubcategory(e.target.value))
            .then(function (response) {
         
              if (response.data.code === 1){
                setSubcategory(response.data.data);
              
                
              }else {
                toast.error('No Subcategory found!', {
                  position: "top-center"
                });
              }
                
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });

            setCategoryID(e.target.value);
    }


      const uploadImages = (e) => {
         
          for (var i = 0 ; i < e.target.files.length; i ++ ){
            setImages([...Images, e.target.files[i]]);
          }
          
         
      }

      const handleSubmit = (e) => {

        e.preventDefault();

       let formData = new FormData();

       formData.append('product_title', title);
       formData.append('product_code', productCode);
       formData.append('image', productImage);
       formData.append('price', price);
       formData.append('special_price', special_price);
       formData.append('currency', currency);
       formData.append('product_brand', brand);
       formData.append('selected_category', categoryID);
       formData.append('subcategory', subCategoryID);
       formData.append('sizes', sizes);
       formData.append('colors', colors);
       formData.append('description', shortDescription);
       formData.append('long_description', description);
       formData.append('remark', remark);
       formData.append('product_image', Images);

        axios.post(AppURL.AddProduct, formData).then(function(response){
            
            if (response.data.code === 1 ){
              document.getElementById('product_form').reset();
              clearData();
              toast.success(response.data.message, {
                position: "top-center"
              })
            }
            
        }).catch(function(error){
          
          if (error.response.data.errors){
            console.log(error.response.data.errors);
                      if (error.response.data.errors['product_image']){ 
                        toast.error(error.response.data.errors['product_image'][0], {
                        position: "top-center"
                      });
                    }
                    if (error.response.data.errors['product_code']){ 
                      toast.error(error.response.data.errors['product_code'][0], {
                      position: "top-center"
                    });
                  }
                  if (error.response.data.errors['image']){ 
                    toast.error(error.response.data.errors['image'][0], {
                    position: "top-center"
                  });
                }
                if (error.response.data.errors['price']){ 
                  toast.error(error.response.data.errors['price'][0], {
                  position: "top-center"
                });
              }
                if (error.response.data.errors['currency']){ 
              toast.error(error.response.data.errors['currency'][0], {
              position: "top-center"
            });
          }
          if (error.response.data.errors['product_brand']){ 
            toast.error(error.response.data.errors['product_brand'][0], {
            position: "top-center"
          });
          }
          if (error.response.data.errors['selected_category']){ 
            toast.error(error.response.data.errors['selected_category'][0], {
            position: "top-center"
          });
          }
          if (error.response.data.errors['subcategory']){ 
            toast.error(error.response.data.errors['subcategory'][0], {
            position: "top-center"
          });
          }
          if (error.response.data.errors['sizes']){ 
            toast.error(error.response.data.errors['sizes'][0], {
            position: "top-center"
          });
          }
          if (error.response.data.errors['colors']){ 
            toast.error(error.response.data.errors['colors'][0], {
            position: "top-center"
          });
          }
          if (error.response.data.errors['description']){ 
            toast.error(error.response.data.errors['description'][0], {
            position: "top-center"
          });
          }
          if (error.response.data.errors['remark']){ 
            toast.error(error.response.data.errors['remark'][0], {
            position: "top-center"
          });
          }
          }
      
          
          
        });

      }

      function clearData(){
        setTitle('');
        setProductCode('');
        setProductImage('');
        setPrice('');
        setSpecialPrice('');
        setCurrency('');
        setBrand('');
        setShortDescription('');
        setDescription('');
        setRemark('');
        setImages([]);
      }


    const KeyCodes = {
      TAB: 9,
      comma: 188,
      enter: 13
    };

    const delimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.TAB];

    const handleDelete = i => {
      setColors(colors.filter((color, index) => index !== i));
    };


    const handleDeleteSize = i => {
      setSizes(sizes.filter((size, index) => index !== i));
    };

    const handleAdditionSize = size => {
      setSizes([...sizes, size]);
    };

    const handleDragSizes = (size, currPos, newPos) => {
      const newTags = colors.slice();
  
      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, size);
  
      // re-render
      setSizes(newTags);
    };
    const handleTagClickSizes = index => {
      console.log('The tag at index ' + index + ' was clicked');
    };
  
    const handleAddition = color => {
      setColors([...colors, color]);
    };
  
    const handleDrag = (color, currPos, newPos) => {
      const newTags = colors.slice();
  
      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, color);
  
      // re-render
      setColors(newTags);
    };
  
    const handleTagClick = index => {
      console.log('The tag at index ' + index + ' was clicked');
    };
  


    const getSubcategory = () => {
      return ( subcategory.map((record, key) => {
        return <option key={key} value={record.id}>{record.subcategory_name}
               </option>;
      })
      );
    }

    const handleKeyDown = (e) =>{
      if (e.key !== "Enter") return
         const value = e.target.value;
      
      if (!value.trim()) return 
      setColors([...colors, value]);
      e.target.value='';
    }

    function removeTag(index){
      setColors(colors.filter((el, i) => i !== index ))
    }



    
    if (!localStorage.getItem('token')){
        return <Navigate to='/login' />
      }

  return (
    <Fragment>
       
    <Container >
  <Row>
    <Col lg={4} md={4} sm={12} xs={12} >

      <Card style={{width: '18rem'}}>
        <Card.Img className='userprofile' variant='top' src={props.user.profile_photo_path}/>
        <Card.Body>
          <Card.Title > Title </Card.Title>

        </Card.Body>
          <ListGroup className='list-group-flush'>
            <ListGroupItem><Link className='text-link' to='/orderlist'> <p className='product-name-on-card'>  Order List</p> </Link></ListGroupItem>
            <ListGroupItem className={props.user.type}><Link className={'text-link '+ props.user.type} to='/delieveredorders'> <p className='product-name-on-card'>  Delievered Orders</p> </Link></ListGroupItem>
            <ListGroupItem className={props.user.type}><Link className={'text-link '+ props.user.type} to='/shippedorders'> <p className='product-name-on-card'>  Shipped Orders</p> </Link></ListGroupItem>
            <ListGroupItem className={props.user.type}><Link className={'text-link '+ props.user.type} to='/processedorders'> <p className='product-name-on-card'>  Processed Orders</p> </Link></ListGroupItem>
            <ListGroupItem className={props.user.type}><Link className={'text-link '+ props.user.type} to='/neworders'> <p className='product-name-on-card'>  New Orders</p> </Link></ListGroupItem>
            <ListGroupItem className={props.user.type}><Link className={'text-link '+ props.user.type} to='/addproduct'> <p className='product-name-on-card'>  Add Products</p> </Link></ListGroupItem>
            <ListGroupItem className={props.user.type}><Link className={'text-link '+ props.user.type} to='/products'> <p className='product-name-on-card'>  Products</p> </Link></ListGroupItem>
          </ListGroup>
         


      </Card>
      
    </Col>
    <Col lg={8} md={8} sm={12} xs={12} >
    <ul className='list-group'>
            <li className='list-group-item'>
                Name : {props.user.name}  
            </li>
            <li className='list-group-item'>
                Email : {props.user.email} 
            </li>
            <li className='list-group-item'>
                Type : {props.user.type} 
            </li>

        </ul>

                <div className='form-group col-sm-9 col-md-9 mt-5' >
                    <form action="" onSubmit={handleSubmit} encType='multipart/form-data' id='product_form'>
                        <div className='form-group field item'>
                            
                          <h6 className='text-center'> Edit Product  {state.pid} </h6>
                           
                             <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="title" value={title} onChange={(e)=> {setTitle(e.target.value)}} placeholder="Laptop 3306, Clothes, Car, Mobile etc..."/>
                                <label for="title">Product Title</label>
                                </div>
                                
                                  <div class="input-group has-validation mt-2">
                                   
                                    <span class="input-group-text" onClick={generateProductCode}> <FontAwesomeIcon icon={faKey}></FontAwesomeIcon></span>
                                    <div class="form-floating is-invalid">
                                        <input type="text" class="form-control-plaintext" value={productCode} id="prodcut_code" readOnly placeholder="Product Code" required />
                                        <label htmlFor="prodcut_code">Product Code</label>
                                    </div>
                                    </div>

                                    <div class="mb-3">
                                    <label for="iconImage" class="form-label">Select File</label>
                                    <input class="form-control" type="file" onChange={setIconImage} id="iconImage"/>
                                    </div>

                                    <div class="mb-3">
                                        <img src={icon} id='icon_image' height={150} width={150} class="img-thumbnail" alt="icon"/>
                                    </div>

                                    <div class="form-floating mt-2">
                                      <input type="text" class="form-control" value={price} onChange={(e) =>{ setPrice(e.target.value)} }  id="price" placeholder="00.00"/>
                                      <label for="price">Price </label>
                                    </div>

                                    <div class="form-floating mt-2">
                                      <input type="text" class="form-control" value={special_price} onChange={(e) =>{ setSpecialPrice(e.target.value)} } id="speecial_price" placeholder="00.00"/>
                                      <label for="special_price">Special Price </label>
                                    </div>

                                    <div class="form-floating mt-2">
                                      <input type="text" class="form-control" value={currency} onChange={(e) =>{ setCurrency(e.target.value)} }  id="currency" placeholder="USD, PKR, AUS, EURO etc..."/>
                                      <label for="currency">Currency </label>
                                    </div>

                                    <div class="form-floating mt-2">
                                      <input type="text" class="form-control" value={brand} onChange={(e) =>{ setBrand(e.target.value)} }  id="product_brand" placeholder=" Dell, Toyota, Dolce & Gabanna etc..."/>
                                      <label for="product_brand">Product Brand </label>
                                    </div>

                                    <div class="form-floating mt-2">
                                      <select class="form-control" id='remark' onChange={(e) => { setRemark(e.target.value);}}>
                                        <option value='New'> New </option>
                                        <option value='Used'> Used </option>
                                        <option value='Collection'> Collection </option>

                                      </select>
                                
                                      <label for="remark">Remark </label>
                                    </div>

                                    <div class="form-floating mt-2">
                                      <select class="form-control" id='subcategory' onChange={loadSubcategory}>
                                      {category.map((e, key) => {
                                          return <option key={key} value={e.category_id}>{e.category_name}</option>;
                                      })}

                                      </select>
                                
                                      <label for="category">Category </label>
                                    </div>

                                    <div class="form-floating mt-2">
                                      <select class="form-control" id='subcategory' onChange={(e) => {setSubCategoryID(e.target.value)}}>
                                          <option></option>
                                          {getSubcategory()}
                                      </select>
                                
                                      <label for="subcategory">Subcategory </label>
                                    </div>
                                    
                                    <div class="form-floating mt-2">
                                      <input type="text" class="form-control"  id="sizes" onChange={(e) => {setSizes(e.target.value)}} placeholder="Small, Medium, Large, M, L,XL etc..."/>
                                      <label for="sizes">Size </label>
                                    </div>

                                    <div class="form-floating mt-2 tags-input-container">
                                      
                                        {colors.map((tag, index) => {
                                          <div className='tag-item' key={index}>
                                          <span className='text'> {tag} </span>
                                          <span className='text' onClick={() => {
                                            removeTag(index)
                                          }}> &times; </span>
                                          </div>
                                        })}
                                      <input type="text" class="form-control tags-input" id="colors" onKeyDown={handleKeyDown} onChange={(e) => {setColors(e.target.value)}} placeholder="Small, Medium, Large, M, L,XL etc..."/>
                                      <label for="colors">Color </label>
                                      
                                    </div>


                                    <div className='form-floating mt-2'>  

                                    <input class="form-control" id='description' value={shortDescription} onChange={(e)=> {setShortDescription(e.target.value)}} placeholder='description' />
                                      
                                 
                                    <label for="colors">Short Description </label>
                                    </div>


                                    <div className='form-floating mt-2'>
                                      
                                    <ReactTags

                                      classNames={{ tagInput: 'form-control',
                                      tagInputField: 'form-control',}}
                                      tags={colors}
                                      delimiters={delimiters}
                                      handleDelete={handleDelete}
                                      handleAddition={handleAddition}
                                      handleDrag={handleDrag}
                                      handleTagClick={handleTagClick}
                                      inputFieldPosition="inline"
                                      placeholder='Color: ex, Blue, Green, White, Yellow'
                                      autocomplete
                                    />
                                    
                                  </div>

                                  <div className='form-floating mt-2'>

                                                                      
                                    <ReactTags
                                    classNames={{ tagInput: 'form-control',
                                    tagInputField: 'form-control',}}
                                      tags={sizes}
                                      delimiters={delimiters}
                                      handleDelete={handleDeleteSize}
                                      handleAddition={handleAdditionSize}
                                      handleDrag={handleDragSizes}
                                      handleTagClick={handleTagClickSizes}
                                      inputFieldPosition="inline"
                                      placeholder='Sizes: eg, M, L, XL, Small, Medium, Large'
                                      autocomplete
                                      labelField={'Sizes: '}
                                    />
                                   
                                    
                                  </div>

                                  <div className='form-floating mt-2'>  

                                    <textarea class="form-control" id='description' onKeyUp={(e)=> {setDescription(e.target.value)}} onChange={(e)=> {setDescription(e.target.value)}} placeholder='description' cols={20} rows='15'>
                                      {description}
                                    </textarea>
                                    <label for="colors">Long Description </label>
                                    </div>


                                    <div className='form-floating mt-2 mb-3'>

                                      <input className='form-control' type="file" id='images' onChange={uploadImages} multiple placeholder='Please selecte images'/>


                                        <label for="images"> Images </label>
                                    </div>

                                    <div className='form-floating mt-2 mb-3' id='product_images' >


                                    </div>

                        </div>
                        

                        <input type="submit" className='btn btn-primary' value="Submit" />
                    </form>

                    
                </div>

                <ToastContainer theme="dark" />
                          
    </Col> 
  </Row>
</Container>
    </Fragment>
  )
}

export default ProductEdit
