import axios from 'axios';
import React from 'react'
import { Fragment } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Card, Col, Container, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import AppURL from '../../api/AppURL';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function DeliveredOrders(props) {

  var [orders, setOrders] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {

    axios.get(AppURL.getOrders(props.email)).then(function(response){
        if (response.data.code === 1 ){
          setOrders(response.data.orders);
        }else {

        }
    }).catch(function(error){

    });
  },[]);

  const proceedForProcess = (e) => {

    navigate('/products/process', {state:{invoice: e.target.getAttribute('data')}})

  }

  const loadProducts = () =>{
    return ( orders.map((record, key) => {
        return (
    
        <tr className='mt-2'>
        <td >{key + 1}</td>
        <td > <span className='text-primary'>{record.invoice_no }</span></td>
        <td >{record.product_name}</td>
        <td>{record.product_code}</td>
        <td>{record.quantity}</td>
        <td>{record.unit_price}</td>
        <td>{record.delivery_charge}</td>
        <td>{record.total_price}</td>
        <td>{record.currency}</td>
        <td>{record.product_size.split(':')[1]}</td>
        <td>{record.product_color.split(':')[1]}</td>
        <td><span className='text-success'>{record.order_status}</span></td>
        <td>{record.customer_contact_no}</td>
        <td>{record.payment_method}</td>
        <td>{record.delivery_address}</td>
        <td>{record.order_date}</td>
        <td>{record.order_time}</td>
        <td>{record.description}</td>
        <td><button className='btn btn-info' data={record.invoice_no} onClick={proceedForProcess}>view</button></td>
        <td><button className='btn btn-danger' data={record.id} >delete</button></td>
        <td><button className='btn btn-primary' data={record.id} >Edit</button></td>
        </tr>
            )
      })
      );
    }

  return (
    <Fragment>
       
        <Container >
      <Row>
        <Col lg={4} md={4} sm={4} xs={4} >
    
          <Card style={{maxWidth: '18rem'}}>
            <Card.Img className='userprofile' variant='top' src={props.user.profile_photo_path} />
            <Card.Body>
              <Card.Title > Title </Card.Title>
    
            </Card.Body>
              <ListGroup className='list-group-flush'>
                <ListGroupItem><Link className='text-link' to='/orderlist'> <p className='product-name-on-card'>  Order List</p> </Link></ListGroupItem>
                <ListGroupItem className={props.user.type}><Link className={'text-link '+ props.user.type} to='/delieveredorders'> <p className='product-name-on-card'>  Delievered Orders</p> </Link></ListGroupItem>
                <ListGroupItem className={props.user.type}><Link className={'text-link '+ props.user.type} to='/shippedorders'> <p className='product-name-on-card'>  Shipped Orders</p> </Link></ListGroupItem>
                <ListGroupItem className={props.user.type}><Link className={'text-link '+ props.user.type} to='/processedorders'> <p className='product-name-on-card'>  Processed Orders</p> </Link></ListGroupItem>
                <ListGroupItem className={props.user.type}><Link className={'text-link '+ props.user.type} to='/neworders'> <p className='product-name-on-card'>  New Orders</p> </Link></ListGroupItem>
                <ListGroupItem className={props.user.type}><Link className={'text-link '+ props.user.type} to='/addproduct'> <p className='product-name-on-card'>  Add Products</p> </Link></ListGroupItem>
                <ListGroupItem className={props.user.type}><Link className={'text-link '+ props.user.type} to='/products'> <p className='product-name-on-card'>  Products</p> </Link></ListGroupItem>
              
              </ListGroup>
             
    
          </Card>
          
        </Col>
        <Col lg={8} md={8} sm={12} xs={12} >
        

            <ul className='list-group mt-3'>
            <table striped bordered hover size="sm">
            <tr>
                <th>No #</th>
                <th>Invoice No </th>
                <th>Title</th>
                <th>Product Code </th>
                <th>Quantity</th>
                <th>unit price</th>
                <th>delivery charge</th>
                <th>Total</th>
                <th>Currency</th>
                <th>Size</th>
                <th>Color</th>
                <th>status</th>
                <th>contact</th>
                <th>payment</th>
                <th>address</th>
                <th>order date</th>
                <th>order time</th>
                <th> Action </th>
            </tr>
            <tbody>
                {loadProducts()}
                
            </tbody>
           </table>
            </ul>


      
        </Col> 

        

        </Row>
    </Container>

        <ToastContainer theme="dark" />
    </Fragment>
  )
}

export default DeliveredOrders
