import React, { Component, Fragment } from 'react'
import { Breadcrumb, Button, Col, Container, Form, Row } from 'react-bootstrap'
import parse from 'html-react-parser';
import axios from 'axios';
import AppURL from '../../api/AppURL';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

export class purchase extends Component {

  constructor(){
    super();

    this.state = {
        purchase : '',

    }

     
}
componentDidMount (){

    let SiteInfoPurchase = sessionStorage.getItem('AllSiteInfo');
    if (SiteInfoPurchase == null ){

            axios.get(AppURL.AllSiteInfo).then(response => {

              if (response.status == 200 ){

                  
                  this.setState({
                      purchase: (response.data)['purchase_guide'],
                      loaderDiv: 'd-none',
                        mainDiv: ''
                  });

                  sessionStorage.setItem('AllSiteInfo',response.data['purchase_guide'] );
              } else {
                toast.error('Something went wrong!', {
                  position: "top-center"
                });
              }

      }).catch(error => {
        toast.error('Something went wrong!', {
          position: "top-center"
        });
      });

    } else {
        this.setState({ purchase: SiteInfoPurchase , loaderDiv: 'd-none',
        mainDiv: ''});
    }

   
}
  render() {
    return (
        <Fragment>
        <Container>

        <div className='breadbody'>
        <Breadcrumb>
      <Breadcrumb.Item > <Link to="/">Home</Link> </Breadcrumb.Item>
      
      <Breadcrumb.Item ><Link to="/purchase">Purchase</Link></Breadcrumb.Item>
    </Breadcrumb>
    </div>

            <Row className='p-2'>
                <Col className='shadown-sm bg-white mt-2' lg={12} md={12} sm={12} xs={12}>

                    <div className={this.state.loaderDiv}>
                            <div class="ph-item">
                                <div class="ph-col-12">
                                    <div class="ph-picture"></div>
                                    <div class="ph-row">
                                        <div class="ph-col-6 big"></div>
                                        <div class="ph-col-4 empty big"></div>
                                        <div class="ph-col-2 big"></div>
                                        <div class="ph-col-4"></div>
                                        <div class="ph-col-8 empty"></div>
                                        <div class="ph-col-6"></div>
                                        <div class="ph-col-6 empty"></div>
                                        <div class="ph-col-12"></div>
                                    </div>
                                </div>
                            </div>
                      </div>
                    

                        <div className={this.state.mainDiv}>

                          <h4 className='section-title-login'> Purchase Page </h4>
                          <p className='section-title-contact'>
                          {parse(this.state.purchase)}
                            </p>
                        </div>
                
                </Col>
            </Row>
        </Container>
        <ToastContainer />
    </Fragment>
    )
  }
}

export default purchase
