import React, { Component, Fragment, useEffect, useState } from 'react'
import NavMenuDesktop from '../components/common/NavMenuDesktop'
import NavMenuMobile from '../components/common/NavMenuMobile'
import FooterDesktop from '../components/common/FooterDesktop'
import FooterMobile from '../components/common/FooterMobile'
import ProductDetails from '../components/ProductDetails/ProductDetails'
import SuggestedProduct from '../components/ProductDetails/SuggestedProduct'
import { useParams } from 'react-router'
import AppURL from '../api/AppURL'
import axios from 'axios'
import SliderLoading from '../components/PlaceHolder/SliderLoading'

export function ProductDetailsPage (props) {
  const params = useParams();
  var [code, setCode] = useState();
  var [isLoading, setIsLoading] = useState('');
  var [mainDiv, setMainDiv] = useState('d-none');
  var [ProductData, setProductData] = useState([]);

  useEffect(() => {
       setCode(params.code);
     
      window.scroll(0,0);

      axios.get(AppURL.ProductDetails(code)).then(
            response => {
                // this.setState({ProductData: response.data});
                setProductData(response.data);
                setIsLoading("d-none");
                setMainDiv('')
            }
      ).catch(
          error => {

          }
      );
  }, code);

  const User = props.user;

  if (mainDiv == 'd-none'){
    <Fragment>
    <div className='Desktop'>
      <NavMenuDesktop />
    </div>
    <div className='Mobile'>
    <NavMenuMobile />
    </div>
    <SliderLoading isLoading={isLoading} />
    
    <div className='Desktop'>
    <FooterDesktop />
    </div>

    <div className='Mobile'>
      <FooterMobile />
    </div>
  </Fragment>
  }

  else {
    <Fragment>
    <div className='Desktop'>
      <NavMenuDesktop user={User} />
    </div>
    <div className='Mobile'>
    <NavMenuMobile />
    </div>
  
   
    <ProductDetails data={ProductData} user={User} />
    <SuggestedProduct />
    
    
    <div className='Desktop'>
    <FooterDesktop />
    </div>

    <div className='Mobile'>
      <FooterMobile />
    </div>
  </Fragment>
  }

    return (
        <Fragment>
        <div className='Desktop'>
          <NavMenuDesktop user={User}/>
        </div>
        <div className='Mobile'>
        <NavMenuMobile />
        </div>
      
       
        <ProductDetails data={ProductData} user={User}/>
        
        <div className='Desktop'>
        <FooterDesktop />
        </div>

        <div className='Mobile'>
          <FooterMobile />
        </div>
      </Fragment>
    )
  
}

export default ProductDetailsPage
