import React from 'react'
import NavMenuDesktop from '../components/common/NavMenuDesktop'
import NavMenuMobile from '../components/common/NavMenuMobile'
import FooterDesktop from '../components/common/FooterDesktop'
import FooterMobile from '../components/common/FooterMobile'
import { Fragment } from 'react'
import AddProduct from '../components/ProductDetails/AddProduct'
function AddProductPage(props) {
  return (
    <Fragment>
        <div className='Desktop'>
          <NavMenuDesktop user={props.user} />
        </div>
        <div className='Mobile'>
        <NavMenuMobile />
        </div>
      
        <AddProduct user={props.user}/>
        
        <div className='Desktop'>
        <FooterDesktop />
        </div>

        <div className='Mobile'>
          <FooterMobile />
        </div>
      </Fragment>
  )
}

export default AddProductPage
