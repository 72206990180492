import axios from 'axios';
import React, { Component, Fragment } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import AppURL from '../../api/AppURL';
import Forget from '../../assets/images/forget.jpg'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class ForgetPassword extends Component {

    constructor(){
        super();
        this.state = {
            email : "",
            message : "",
        }
    }

     // Login Form Submit Method 
     FormSubmit = (e) => {
        e.preventDefault();

        const data = {
            email: this.state.email
        }

        axios.post(AppURL.ForgetPassword, data ).then(response => {

            console.log(response.data);
            this.setState({message: response.data.message});

            toast.success(this.state.message, {
                position: "top-center"
              });
        }).catch(error => {
            this.setState({message: error.response.data.message});
            toast.error(this.state.message, {
                position: "top-right"
              });
        });
    }

  render() {
    return (
        <Fragment>
        <Container>
            <Row className='p-2'>
                <Col className='shadown-sm bg-white mt-2' lg={12} md={12} sm={12} xs={12}>

                    <Row className='text-center'>
                        <Col className='d-flux justify-content-center'  lg={6} md={6} sm={12} xs={12}>
                        
                            <Form className='onboardForm' onSubmit={this.FormSubmit}>
                                <h4 className='section-title-login'>  Forget Password </h4>
                                <h6 className='section-sub-title'></h6>
                                <h6 > </h6>

                                <input onChange={(e) => {this.setState({email: e.target.value})}} className='form-control m-2' type='email' placeholder='Please type your email' />

                                <Button type='submit' className='btn btn-blok m-2 site-btn-login'> Reset Password </Button>
                                <br></br> <br></br>
                                <hr></hr>

                                <p> <b> Forgot my Password ? </b> <Link to='/register'> <b>Register</b> </Link></p>

                                <p> <b> Already Have an account ? </b> <Link to='/login'> <b>Login</b> </Link></p>
                            </Form>
                        </Col>
                        
                        <Col className='p-0 Desktop m-0' lg={6} md={6} sm={6} xs={6}>

                            <img className='onboardBanner'  src={Forget} />
                        
                        </Col>
                        
                    </Row>
                
                </Col>
            </Row>
        </Container>
        <ToastContainer />
    </Fragment>
    )
  }
}

export default ForgetPassword
