import React, { Component, Fragment } from 'react'
import { Button, Col, Container, Navbar, Row } from 'react-bootstrap'
import { Link, Navigate } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faSearch, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import MegaMenuAll from '../home/MegaMenuAll';
import axios from 'axios';
import AppURL from '../../api/AppURL';

export class NavMenuDesktop extends Component {

  constructor(){
    super();
    this.state = {
      SideNavState : 'sideNavClose',
      ContentOverState: 'ContentOverlayClose',
      SearchKey: '',
      SearchRedirectStatus: false,
      CartCount: 0 ,
    }

    this.SearchOnChange = this.SearchOnChange.bind(this);
    this.SearchOnClick = this.SearchOnClick.bind(this);
    this.SearchRedirect = this.SearchRedirect.bind(this);

  }

  componentDidMount(){
    if (this.props.user !== undefined ){
    let product_code = this.props.user.email;
     console.log(product_code);
      axios.get(AppURL.CartCount(product_code)).then(response => {
      
        this.setState({CartCount: response.data });
    }).catch(error => {

    });
  }
    
  }


  logout = () => {
    localStorage.clear();
    
  }

  SideNavOpenClose = () => {
    let SideNavState = this.state.SideNavState;
    let ContentOverState = this.state.ContentOverState;
    if (SideNavState === "sideNavOpen"){
      this.setState({SideNavState: "sideNavClose", ContentOverState: "ContentOverlayClose"})
    }else {
      this.setState({SideNavState: "sideNavOpen", ContentOverState: "ContentOverlayOpen"})
    }
  }

  MenuBarClickHandler = () => {
    this.SideNavOpenClose();


  }

  ContentOverlayClickHandler = () => {
    this.SideNavOpenClose();
  }

  SearchOnChange = (event) => {
      let search_key = event.target.value;
      this.setState({SearchKey: search_key});

  }

  SearchOnClick = () => {

    if (this.state.SearchKey.length >= 2 ){
          this.setState({SearchRedirectStatus: true})
    }else {

    }
  }

  SearchRedirect(){
    if (this.state.SearchRedirectStatus ){

      return <Navigate replace to={'/productlistsearch/'+ this.state.SearchKey} /> 
    }
  }

  render() {


    let buttons;
    if (localStorage.getItem('token')){
        buttons = (
          <div>
          <Link to='/favourite' className='btn' >  <FontAwesomeIcon icon={faHeart}/>  <sup> <span className='badge text-white bg-danger'></span></sup></Link>
          <Link to='/notification' className='btn' >  <FontAwesomeIcon icon={faBell}/>  <sup> <span className='badge text-white bg-danger'></span></sup></Link>
       
          <Link to='/profile' className='h4 btn'>PROFILE</Link> 
          <Link to='/' onClick={this.logout} className='h4 btn'>LOGOUT</Link> 
          <Link to='/cart' className='cart-btn'> <FontAwesomeIcon icon={faShoppingCart}/> {this.state.CartCount} items</Link>
          </div>
        )
    }else {
      buttons = (
        <div>
        <Link to='/favourite' className='btn' >  <FontAwesomeIcon icon={faHeart}/>  <sup> <span className='badge text-white bg-danger'>5</span></sup></Link>
        <Link to='/notification' className='btn' >  <FontAwesomeIcon icon={faBell}/>  <sup> <span className='badge text-white bg-danger'>5</span></sup></Link>
     
        <Link to='/login' className='h4 btn'>LOGIN</Link> 
        <Link to='/register' className='h4 btn'>Register</Link> 
        <Link to='/cart' className='cart-btn'> <FontAwesomeIcon icon={faShoppingCart}/> 0 items</Link>
        </div>
      )
    }

    return (
      <Fragment>
        <div className='TopSectionDown'>
        <Navbar className='navbar' fixed={'top'} bg='light'>
        <Container fluid={'true'} className='fixed-top shadow-sm p-2 mb-0 bg-white'>

      
          <Row>
            <Col lg={4} sm={12} md={4} xs={12}>
             <Link to='/'> <img src={logo} alt="" className='nav-logo'  /></Link>
            </Col>
            <Col className='p-1 mt-1' lg={4} sm={12} md={4} xs={12}>
              <div className='input-group w-100'>
              <input onChange={this.SearchOnChange} type='text' className='form-control'/>
              <Button type='button' onClick={this.SearchOnClick} className='btn site-btn'> <FontAwesomeIcon icon={faSearch}/> </Button>
              </div>
            </Col>
            
            <Col className='p-1 mt-1' lg={4} sm={12} md={4} xs={12}>
                {buttons}
            </Col>
          </Row>

          {this.SearchRedirect()}
        </Container>

        </Navbar>
        </div>

        <div className={this.state.SideNavState}>
                  <MegaMenuAll />
                </div>

                  <div onClick={this.ContentOverlayClickHandler} className={this.state.ContentOverState}>
                    
                  </div>

      </Fragment>
    )
  }
}

export default NavMenuDesktop
