import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { Fragment } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Card, Col, Container, ListGroup, ListGroupItem, Modal, Row } from 'react-bootstrap'
import toast, { Toaster } from 'react-hot-toast';
import { Navigate } from 'react-router';
import { Link } from 'react-router-dom';
import AppURL from '../../api/AppURL';

function ProcessedOrders(props) {

    var [orders, setOrders] = useState([]);
    var [amounts, setAmount] = useState([]);
    var [currency, setCurrency] = useState([]);

    let name ;
    let email ;
    let type ;
    let userTypeFlage;

    if (props.user){
      name = props.user.name;
      email =props.user.email;
      type = props.user.user_type;

      if (type === 'Personal'){
        userTypeFlage = 'd-none';
      }else {
        userTypeFlage = '';
      }
      
    }

    useEffect(() => {
        axios.get(AppURL.getInProcessOrders()).then(function(response){
            if (response.data.code === 1 ){
                setOrders(response.data.orders);
                setAmount(response.data.total_amount);
                setCurrency(response.data.currency);
                
            } else {
                toast.error(response.data.message);
            }
            
        }).catch(function(error){

        });
    },[]);


    const deliverItem = (e) => {

        alert(e.target.getAttribute('data'));

        axios.get(AppURL.sentForShipment(e.target.getAttribute('data'))).then(function(response){
            if (response.data.code === 1 ){
                toast.success(response.data.message);
            }else {
                toast.error(response.data.message);
            }
        }).catch(function(error){

        });

    }

    const rejectOneItem = (e) => {

        axios.get(AppURL.rejectItem(e.target.getAttribute('data'))).then(function(response){

            if (response.data.code === 1 ){
                toast.success(response.data.message);
            }else {
                toast.error(response.data.message);
            }

        }).catch(function(error){
            console.log(error);
        });
    }



    if (!localStorage.getItem('token')){
        return <Navigate to='/login' />
      }

  return (
    
    <Fragment>

        <Container >

            <div className='section-title text-center mb-55'> <h2> Proccessed Orders By ( {props.user.name} ) </h2>
                    
                </div>

                <Row>
    <Col lg={4} md={4} sm={12} xs={12} >

      <Card style={{maxWidth: '18rem', marginLeft: 0}}>
        <Card.Img className='userprofile' variant='top' src={props.user.profile_photo_path}/>
        <Card.Body>
          <Card.Title > {props.user.name} </Card.Title>

        </Card.Body>
          <ListGroup className='list-group-flush'>
            <ListGroupItem><Link className='text-link' to='/orderlist'> <p className='product-name-on-card'>  Order List</p> </Link></ListGroupItem>
            <ListGroupItem className={userTypeFlage}><Link className={'text-link '+ userTypeFlage} to='/delieveredorders'> <p className='product-name-on-card'>  Delievered Orders</p> </Link></ListGroupItem>
            <ListGroupItem className={userTypeFlage}><Link className={'text-link '+ userTypeFlage} to='/shippedorders'> <p className='product-name-on-card'>  Shipped Orders</p> </Link></ListGroupItem>
            <ListGroupItem className={userTypeFlage}><Link className={'text-link '+ userTypeFlage} to='/processedorders'> <p className='product-name-on-card'>  Processed Orders</p> </Link></ListGroupItem>
            <ListGroupItem className={userTypeFlage}><Link className={'text-link '+ userTypeFlage} to='/neworders'> <p className='product-name-on-card'>  New Orders</p> </Link></ListGroupItem>
            <ListGroupItem className={userTypeFlage}><Link className={'text-link '+ userTypeFlage} to='/addproduct'> <p className='product-name-on-card'>  Add Products</p> </Link></ListGroupItem>
            <ListGroupItem className={userTypeFlage}><Link className={'text-link '+ userTypeFlage} to='/products'> <p className='product-name-on-card'>  Products</p> </Link></ListGroupItem>
          
          </ListGroup>
         


      </Card>
      
    </Col>
    <Col lg={8} md={8} sm={12} xs={12} >

    
        {  orders.map((order, index) => {

            return (
                <Card className='image-box card w-100 mt-3' key={index}>
                                <Card.Header className='text-center bg-light'> <h5 className='text-primary'> <span className='text-dark'>Invoice No:</span>  { order.invoice_no} </h5></Card.Header>
                               

                                <Card.Body>
                                    <Card.Title className='text-center'> <span className='text-info'>{order.product_name}</span></Card.Title>
                                    <p className='product-price-on-card'>Product Code :  {order.product_code} </p>
                                    <p className='product-name-on-card'> {order.product_size} </p>
                                    <p className='product-price-on-card'> {order.product_color} </p>

                                    <div>
                                        <h5 className='text-center'> Customer Info </h5>
                                        <p> {order.customer_name}</p>
                                        <p> {order.customer_contact_no}</p>
                                        <p> {order.delivery_address}</p>
                                        <p> {order.email}</p>
                                    </div>
                                
                                </Card.Body>

                                <Card.Footer className="text-muted text-center">
                                        <h6> <span className='text-danger'> Payment </span></h6>
                                        <p>Quantity: {order.quantity }</p>
                                        <p>Unit Price: {order.unit_price }</p>
                                        <p><span className='text-success'>Total Price: {order.total_price } </span></p>
                                        <p><span className='text-success'>Currency:  {order.currency }</span></p>
                                        <p>Payment Method: {order.payment_method }</p>

                                        <Button variant="primary me-2" data={order.id} onClick={deliverItem} >Deliver</Button>
                                        <Button variant="danger" data={order.id} onClick={rejectOneItem}>Reject</Button>
                                    </Card.Footer>
                            </Card>
            )
        })
        }
                            

            {orders.length === 0? 'No Record Exist': ''}            
                                     
    </Col> 
  </Row>

                    <Toaster />


        </Container>

                <Modal show={false} onHide=''>
                <Modal.Header closeButton>
                <h6><FontAwesomeIcon icon={faBell}/> Post Your Review </h6>
                </Modal.Header>
                <Modal.Body>
                
                <div className='col-md-12 p-1 col-lg-12 col-sm-12 col-12'>
                                            <label className='form-label'> Type Your Name  </label>
                                            <input  className='form-control' type='text' placeholder={props.user.name}></input>
                                            
                </div>

            
                <div className='col-md-12 p-1 col-lg-12 col-sm-12 col-12'>
                                            <label className='form-label'> Select Product Rating  </label>
                                            <select  className='form-control'>
                                                    <option value=''>Choose Option</option>
                                                    <option value='1'>1</option>
                                                    <option value='2'>2</option>
                                                    <option value='3'>3</option>
                                                    <option value='4'>4</option>
                                                    <option value='5'>5</option>
                                            </select>
                                    </div>

                <div className='col-md-12 p-1 col-lg-12 col-sm-12 col-12'>
                                            <label className='form-label'> Type Your Name  </label>
                                            <textarea  rows={3} className='form-control' type='text' placeholder='Your comment'></textarea>
                                            
                </div>


                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" >
                Post
                </Button>
                <Button variant="secondary" >
                Close
                </Button>

                </Modal.Footer>
                </Modal>
        

      </Fragment>
  )
}

export default ProcessedOrders
