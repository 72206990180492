import axios from 'axios';
import React, { Component, Fragment } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import AppURL from '../../api/AppURL';
import Forget from '../../assets/images/forget.jpg'
import 'react-toastify/dist/ReactToastify.css';

export class ResetPassword extends Component {

    constructor(){
        super();
        this.state = {
            token :  '',
            password : "",
            password_confirmation: '',
            email : "",
            message : "",
        }
    }

    // Reset Password 

    FormSubmit = (e) => {
        e.preventDefault();

        const data = {
            token: this.state.token,
            email: this.state.email,
            password: this.state.password,
            password_confirmation: this.state.password_confirmation
        }

        axios.post(AppURL.ResetPassword, data ).then(response => {

            this.setState({message: response.data.message });
            toast.success(this.state.message, {
                position: "top-right"
              });

              document.getElementById('resetform').reset();

        }).catch(error => {
            this.setState({message: error.response.data.message});
            toast.error(this.state.message, {
                position: "top-right"
              });
        });
    }

  render() {
    return (
        <Fragment>
        <Container>
            <Row className='p-2'>
                <Col className='shadown-sm bg-white mt-2' lg={12} md={12} sm={12} xs={12}>

                    <Row className='text-center'>
                        <Col className='d-flux justify-content-center'  lg={6} md={6} sm={12} xs={12}>
                        
                            <Form className='onboardForm' onSubmit={this.FormSubmit} id='resetform'>
                                <h4 className='section-title-login'>  Reset Password</h4>
                                <h6 className='section-sub-title'></h6>
                                <h6 > Please Enter below Information </h6>


                                <input onChange={(e) => {this.setState({token: e.target.value})}} className='form-control m-2' type='text' placeholder='Please enter your PIN Code' />
                                <input onChange={(e) => {this.setState({email: e.target.value})}} className='form-control m-2' type='email' placeholder='Please type your email' />
                                <input onChange={(e) => {this.setState({password: e.target.value})}} className='form-control m-2' type='password' placeholder='Please type your New password' />

                                <input onChange={(e) => {this.setState({password_confirmation: e.target.value})}} className='form-control m-2' type='password' placeholder='Please confirm password' />

                                <Button type='submit' className='btn btn-blok m-2 site-btn-login'> Reset Password </Button>
                                
                            </Form>
                        </Col>
                        
                        <Col className='p-0 Desktop m-0' lg={6} md={6} sm={6} xs={6}>

                            <img className='onboardBanner'  src={Forget} />
                        
                        </Col>
                        
                    </Row>
                
                </Col>
            </Row>
        </Container>
        <ToastContainer />
    </Fragment>
    )
  }
}

export default ResetPassword
