import { faMinus, faPlus, faShoppingCart, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';
import React, { Component, Fragment } from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import AppURL from '../../api/AppURL';
import toast, { Toaster } from 'react-hot-toast';
import { Navigate } from 'react-router';

export class Cart extends Component {

    constructor(){
        super();
    
        this.state = {
            ProductData: [],
            isLoading: '',
          mainDiv: 'd-none',
          confirmBtn: 'Confirm Order', 
          payment: '',
          address: '',
          name: '',
          pageRedirectStatus: false
        }
      }

      componentDidMount (){
        
        axios.get(AppURL.CartList(this.props.user.email)).then(response => {

                if (response.status == 200 ){
                    this.setState({
                        ProductData: response.data
                    });
                }

        }).catch(error => {
                
        });
    }

    removeFromCart = (pid) => {
        
        axios.get(AppURL.RemoveFromCart(pid)).then(response => {
            if (response.data.code === 1){
                toast.success(response.data.message, {position: 'top-right'});
            }else {
                toast.error(response.data.message, {position: 'top-right'});
            }
            

        }).catch(error => {
            toast.error(error.data.message, {position: 'top-right'});
        });
    }


    itemPlus = (pid, quantity, price) => {

        axios.get(AppURL.CartItemPlus(pid, quantity, price)).then(response => {

            if (response.data.code === 1 ){
                toast.success(response.data.message, {position: 'top-right'});
            } else {
                toast.error(response.data.message, {position: 'top-right'});
            }
        }).catch(error => {
            toast.error(error.data, {position: 'top-right'});
        });

    }

    itemMinus = (pid, quantity, price) => {
        
        axios.get(AppURL.CartItemMinus(pid, quantity, price)).then(response => {

            if (response.data.code === 1 ){
                toast.success(response.data.message, {position: 'top-right'});
            } else {
                toast.error(response.data.message, {position: 'top-right'});
            }
        }).catch(error => {
            toast.error(error.data, {position: 'top-right'});
        });

    }

    addressOnChange = (event) => {
        this.setState({address: event.target.value});
    }

    paymentMethodOnChange = (event) => {
        this.setState({payment: event.target.value});
    }

    nameOnChange = (event) => {
        this.setState({name: event.target.value});
    }

    confirmOrder = () => {

        
        if (this.state.address.length === 0 ){
            toast.error('Please enter the delivery address for the order to be placed.', {position: 'top-right'});
        }else if (this.state.payment.length === 0){
            toast.error('Payment method must be selected for the order to be placed.', {position: 'top-right'});
        }else if (this.state.name.length === 0 ){
            toast.error('Please enter the name field for order to be placed for.', {position: 'top-right'});
        }

        let invoice = new Date().getTime();

       let formdata = new FormData();
        formdata.append('payment_method', this.state.payment);
        formdata.append('name', this.state.name);
        formdata.append('delivery_address', this.state.address);
        formdata.append('email', this.props.user.email);
        formdata.append('invoice_no', invoice);
        formdata.append('payment_method', this.state.payment);
        formdata.append('delivery_charge', '00.00');


        axios.post(AppURL.CartOrder, formdata ).then(response => {

            if (response.data.code === 1 ){
                toast.success(response.data.message, {position: 'top-right'});
                this.setState({pageRedirectStatus: true});
            } else {
                toast.error(response.data.message, {position: 'top-right'});
            }
        }).catch(error => {
            toast.error(error.data, {position: 'top-right'});
        });
    }

    pageRedirectStatusCheck = () => {
        if (this.state.pageRedirectStatus){
            <Navigate to='/orderlist' />
        }
    }

   

    

  render() {

    if (!localStorage.getItem('token')){
        return <Navigate to='/login' />
      }
      
    const CartList = this.state.ProductData;
    let totalAmount = 0;
    const MyView = CartList.map((cartList, i) => {
                totalAmount += parseInt(cartList.total_price) ;
                return (
                    
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col md={3} lg={3} sm={6} xs={6}>
                                    <img className='cart-product-img' src={cartList.image} />
                                </Col>
                                <Col lg={6} md={6} sm={6} xs={6}>
                                    <h5 className='product-name'>
                                        {cartList.product_name}
                                    </h5>
                                    <h6>
                                        Quantity = {cartList.quantity}
                                    </h6>
                                    <p> {cartList.size} | {cartList.color}</p>
                                    <h6>Price = {cartList.quantity} X {cartList.unit_price} =  $ {cartList.total_price}  </h6>
                                </Col>

                                <Col md={3} lg={3} xs={12} sm={12}>
                                    
                                    <Button onClick={() => this.itemPlus(cartList.id, cartList.quantity, cartList.unit_price)} className='btn btn-lg mx-1 mt-2 site-btn'> <FontAwesomeIcon icon={faPlus} /> </Button>
                                    <Button onClick={() => this.itemMinus(cartList.id, cartList.quantity, cartList.unit_price)} className='btn btn-lg mx-1 mt-2 site-btn'> <FontAwesomeIcon icon={faMinus} /> </Button>
                                    <Button onClick={() => this.removeFromCart(cartList.id)} className='btn btn-lg mx-1 mt-2 site-btn'> <FontAwesomeIcon icon={faTrashAlt} /> </Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                
                )
    });

    return (
      <Fragment>
        <Container>
        <div className='section-title text-center mb-55'> <h2> Product Cart List </h2>
            
                </div>
            <Row>

            <Col className='p-1' lg={7} md={7} sm={12} xs={12}>

                {MyView}
                </Col>


                <Col className='p-1' lg={5} md={5} sm={12} xs={12}>
                    <div className='p-2 card'>
                        <div className='card-body '>
                            <div className='container-fluid'>
                                <div className='row'>
                                    <div className='col-md-12 p-1 col-lg-12 col-sm-12 col-12'> 
                                        <h5> Total Amount: {totalAmount} $</h5>
                                    </div>

                                </div>
                                <div className='row'>
                                    <div className='col-md-12 p-1 col-lg-12 col-sm-12 col-12'>
                                            <label className='form-label'> Type Delivery Address  </label>
                                            <textarea onChange={this.addressOnChange} className='form-control' rows={2} placeholder='Full Delivery Address and Contact information'>

                                            </textarea>
                                    </div>

                                    <div className='col-md-12 p-1 col-lg-12 col-sm-12 col-12'>
                                            <label className='form-label'> Choose Payment Method  </label>
                                            <select onChange={this.paymentMethodOnChange} className='form-control'>
                                                    <option value=''>Choose Option</option>
                                                    <option value='Cash On Delievery'>Cash On Delivery</option>
                                                    <option value='Online Payment'>Online Payment</option>
                                            </select>
                                    </div>
                                    <div className='col-md-12 p-1 col-lg-12 col-sm-12 col-12'>
                                            <label className='form-label'> Type Your Name  </label>
                                            <input onChange={this.nameOnChange} className='form-control' type='text'></input>
                                    </div>

                                    <div className='col-md-12 p-1 col-lg-12 col-sm-12 col-12'>
                                            
                                            <Button onClick={this.confirmOrder} type='text'> {this.state.confirmBtn} </Button>
                                    </div>


                                </div>

                            </div>

                        </div>

                    </div>
                </Col>
                

            </Row>
            <Toaster />
        </Container>

        {this.pageRedirectStatusCheck()}
      </Fragment>
    )
  }
}

export default Cart
