import React, { Component } from 'react'
import { Fragment } from 'react'
import { Col, Container, Row, Card, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import AppURL from '../../api/AppURL';
import logo from '../../assets/images/app-icon.png';
import axios from 'axios';
import FeaturedLoading from '../PlaceHolder/FeaturedLoading';

export class FeaturedProducts extends Component {

    constructor(){
        super();
    
        this.state = {
            ProductData: [],
            isLoading: '',
          mainDiv: 'd-none'
        }
      }

      componentDidMount (){
        axios.get(AppURL.productlistbyremark('used')).then(response => {
                if (response.status == 200 ){
                    this.setState({
                        ProductData: (response.data),
                        isLoading: 'd-none',
                        mainDiv: ''
                    });
                }
        }).catch(error => {
                
        });
    }

  render() {

    const list  = this.state.ProductData ;

    const MyView = list.map((FeaturedList, i ) => {

        if (FeaturedList.special_price == 0 ){
            
        return          <Col className='p-1' key={i.toString()} xl={2} lg={2} md={2} sm={4} xs={6}> 
                            <Link className='text-link' to={'productDetails/'+ FeaturedList.id} >

                            <Card className='image-box card'>
                                <img className='center' src={FeaturedList.product_image} width="20" height="20"/>
                    
                            <Card.Body>
                                <p className='product-name-on-card'> {FeaturedList.title} </p>
                                     <p className='product-price-on-card'> price : ${FeaturedList.price} </p>
                               
                                   
                            
                            </Card.Body>
                            </Card>
                            </Link>
                            </Col>
                            
        }else {
            return          <Col className='p-1' key={i.toString()} xl={2} lg={2} md={2} sm={4} xs={6}> 
                             <Link className='text-link' to={'productDetails/'+ FeaturedList.id} >

                            <Card className='image-box card'>
                                <img className='center' src={FeaturedList.product_image} width="20" height="20"/>
                    
                            <Card.Body>
                                <p className='product-name-on-card'> {FeaturedList.title} </p>
                                     <p className='product-price-on-card'> price : <strike className='text-secondary'> ${FeaturedList.price}</strike> 
                                             &nbsp; ${FeaturedList.special_price}
                                      </p>
                               
                                   
                            
                            </Card.Body>
                            </Card>
                            </Link>
                            </Col>
        }
    });

    return (
     <Fragment>
        <FeaturedLoading isLoading={this.state.isLoading} />

        <div className={this.state.mainDiv}>
            <Container className='text-center' fluid={true}>
                <div className='section-title text-center mb-55'> <h2> Feature Product</h2>
                    <p> some of our exclusive collection, You may like </p>
                </div>
        <Row>
          
            {MyView}
        </Row>
            </Container>
            </div>
     </Fragment>
    )
  }
}

export default FeaturedProducts
