import axios from 'axios';
import React, { Component, Fragment } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { Link, Navigate } from 'react-router-dom'
import AppURL from '../../api/AppURL';
import Login from '../../assets/images/login.png'

export class UserLogin extends Component {

    constructor(){
        super();
        this.state = {
            password : "",
            email : "",
            message : "",
            loggedin: false
        }
    }

    // Login Form Submit Method 
    FormSubmit = (e) => {
        e.preventDefault();

        const data = {
            email: this.state.email,
            password: this.state.password
        }

        axios.post(AppURL.UserLogin, data ).then(response => {

            localStorage.setItem('token', response.data.token);

            this.setState({message: response.data.message, loggedin: true });

            this.props.setUser(response.data.user);

        }).catch(error => {

        });
    }

    

  render() {

    // AFter Logged in, will redirect to Profile Page

    if (this.state.loggedin){
        return <Navigate to={'/profile'} />
    }

    if (localStorage.getItem('token')){
        return <Navigate to='/profile' />
      }

    return (
        <Fragment>
            <Container>
                <Row className='p-2'>
                    <Col className='shadown-sm bg-white mt-2' lg={12} md={12} sm={12} xs={12}>

                        <Row className='text-center'>
                            <Col className='d-flux justify-content-center'  lg={6} md={6} sm={12} xs={12}>
                            
                                <Form className='onboardForm' onSubmit={this.FormSubmit}>
                                    <h4 className='section-title-login'>  User Sign-In</h4>
                                    <h6 className='section-sub-title'></h6>
                                    <h6 > Please Enter Your Email </h6>

                                    <input onChange={(e) => {this.setState({email: e.target.value})}} className='form-control m-2' type='email' placeholder='Please type your email' />

                                    <input onChange={(e) => {this.setState({password: e.target.value})}} className='form-control m-2' type='password' placeholder='Please type your password' />

                                    <Button type='submit' className='btn btn-blok m-2 site-btn-login'> Login </Button>

                                    <br></br> <br></br>
                                <hr></hr>

                                <p> <b> Forgot my Password ? </b> <Link to='/forget'> <b>Forgot Password</b> </Link></p>

                                <p> <b> Don't Have an account ? </b> <Link to='/register'> <b>Register</b> </Link></p>

                                </Form>
                            </Col>
                            
                            <Col className='p-0 Desktop m-0' lg={6} md={6} sm={6} xs={6}>

                                <img className='onboardBanner'  src={Login} />
                            
                            </Col>
                            
                        </Row>
                    
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
  }
}

export default UserLogin
