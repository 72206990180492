import React, { Component, Fragment } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Card, Col, Container, Row } from 'react-bootstrap';
import slider1 from '../../assets/images/slider-1.jpg';
import slider2 from '../../assets/images/slider-2.jpg';
import slider3 from '../../assets/images/slider-3.jpg';

export class HomeSlider extends Component {
  render() {

    

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

      const SliderData  = this.props.data ;

      const myView = SliderData.map((SliderList, i ) => {

        return <div key={i.toString()}>
        <img className='slider-img' src={SliderList.image_path} alt='' />
      </div>
      });

    return (
        <div>
       
        <Slider {...settings}>
         {myView}
        </Slider>
      </div>
    )
  }
}

export default HomeSlider
