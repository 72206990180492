import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { Component, Fragment } from 'react'
import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap';
import AppURL from '../../api/AppURL';
import toast, { Toaster } from 'react-hot-toast';
import StarRating from 'react-star-rating'
import { Navigate } from 'react-router';

export class OrderList extends Component {

    constructor(){
        super();
    
        this.state = {
            ProductData: [],
        }
      }
      componentDidMount (){
        
        axios.get(AppURL.OrderListByUser(this.props.user.email)).then(response => {

                if (response.status === 200 ){
                    this.setState({
                        show: false,
                        NotificationData: [],
                        ProductData: response.data,
                        name: '',
                        rating: '',
                        comment: '',
                        product_name: '',
                        product_code: '',
                        ReviewModel: false
                    });
                }

        }).catch(error => {
                
        });
    }

    nameOnChange = (event) => {

        let name = event.target.value;
        this.setState({name: name});

    }

    commentsOnChange = (event) => {
        let comment = event.target.value;
        this.setState({comment: comment});
    }

    ratingOnChange = (event) => {

        let rating = event.target.value;
        this.setState({rating: rating});
    }

    productReviewShow = (prod_name, prod_code) => {
        this.setState({product_code: prod_code, product_name: prod_name, show: true, ReviewModel: true});

    }

    productReviewClose = () => {
        this.setState({product_code: '', product_name: '', show: false, ReviewModel: false});
    }

    handlePost = () => {

        if (this.state.name.length === 0 ){
            toast.error('Please type your name.', {position: 'top-right'});
        }else if (this.state.rating.length === 0 || this.state.rating === 'undefined'){
            toast.error('Please select the rating.', {position: 'top-right'});
        }else if (this.state.comment.length > 150 ){
            toast.error('Comments can not be more than 150 characters.', {position: 'top-right'});
        } else {

        

            let review_data = new FormData();
            review_data.append('product_name',this.state.product_name);
            review_data.append('product_code',this.state.product_code);
            review_data.append('reviewer_name',this.state.name);
            review_data.append('reviewer_rate',this.state.rating);
            review_data.append('comments',this.state.comment);
            review_data.append('photo','');


            axios.post(AppURL.PostReview,review_data ).then(response => {
                if (response.data.code === 1 ){
                    toast.success(response.data.message, {position: 'top-center'});
                    this.productReviewClose();
                }else if (response.data.code === 0 ){
                    toast.error(response.data.message, {position: 'top-right'});
                }else {
                    toast.error(response.data, {position: 'top-right'});
                }
                
            }).catch(error => {
                toast.error(error.data, {position: 'top-right'});
            })

        }
        
    }

  render() {

    if (!localStorage.getItem('token')){
        return <Navigate to='/login' />
      }

    const OrderList = this.state.ProductData;
    const MyView = OrderList.map((cartList, i) => {

            return (
                            <div>
                                 <Col lg={6} md={6} sm={6} xs={6}>
                                    <h5 className='product-name'>
                                        {cartList.product_name}
                                    </h5>
                                    <h6>
                                        Quantity = {cartList.quantity}
                                    </h6>
                                    <p> {cartList.product_size} | {cartList.product_color}</p>
                                    <h6>Price = {cartList.quantity} X {cartList.unit_price} =  $ {cartList.total_price}  </h6>
                                    <h6>Product Code: {cartList.product_code} </h6>
                                    <h6> Status :  {cartList.order_status} </h6>
                                    
                                </Col>
                                    
                                
                                    {cartList.order_status === "Delivered" || cartList.order_status === "Received"? 
                                    <div>  <Button data={cartList.id} onClick={(e) => {
                                        axios.get(AppURL.receivedItem(e.target.getAttribute('data'))).then(function(response){
                                                if (response.data.code === 1 ){
                                                    toast.success(response.data.message);
                                                }else {
                                                    toast.error(response.data.error);
                                                }
                                        }). catch(function(error){
                                            toast.error(error.error);
                                        })
                                    }} className='btn btn-success me-4'>Received </Button> 
                                    <Button onClick={() => this.productReviewShow(cartList.product_name, cartList.product_code)} className='btn btn-danger'>Post Review </Button></div>
                                     : ''}
                               
                                    
                                
                                <hr></hr>
                            </div>
            )

    });
    return (
      <Fragment >

        <Container >

        <div className='section-title text-center mb-55'> <h2> Order History By ( {this.props.user.name} ) </h2>
                    
                </div>

                <Card>
                        <Card.Body>
                            <Row>
                                {MyView}
                            </Row>
                        </Card.Body>
                    </Card>
                    <Toaster />

        </Container>

                <Modal show={this.state.ReviewModel} onHide={this.productReviewClose}>
                <Modal.Header closeButton>
                <h6><FontAwesomeIcon icon={faBell}/> Post Your Review </h6>
                </Modal.Header>
                <Modal.Body>
                
                <div className='col-md-12 p-1 col-lg-12 col-sm-12 col-12'>
                                            <label className='form-label'> Type Your Name  </label>
                                            <input onChange={this.nameOnChange} className='form-control' type='text' placeholder={this.props.user.name}></input>
                                            
                </div>

            
                <div className='col-md-12 p-1 col-lg-12 col-sm-12 col-12'>
                                            <label className='form-label'> Select Product Rating  </label>
                                            <select onChange={this.ratingOnChange} className='form-control'>
                                                    <option value=''>Choose Option</option>
                                                    <option value='1'>1</option>
                                                    <option value='2'>2</option>
                                                    <option value='3'>3</option>
                                                    <option value='4'>4</option>
                                                    <option value='5'>5</option>
                                            </select>
                                    </div>

                <div className='col-md-12 p-1 col-lg-12 col-sm-12 col-12'>
                                            <label className='form-label'> Type Your Name  </label>
                                            <textarea onChange={this.commentsOnChange} rows={3} className='form-control' type='text' placeholder='Your comment'></textarea>
                                            
                </div>


                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={this.handlePost}>
                Post
                </Button>
                <Button variant="secondary" onClick={this.productReviewClose}>
                Close
                </Button>

                </Modal.Footer>
                </Modal>
                

      </Fragment>
    )
  }
}

export default OrderList
